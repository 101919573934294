import { css } from "@emotion/react";

import { Typography } from "components/shared/library/Typography";

import { OrgSearchResult, UserSearchResult } from "apis/search";

import { OrgSearchResultItem } from "./OrgSearchResultItem";
import {
  RecentlySearchedItem,
  RecentlySearchedValue,
} from "./RecentlySearchedItem";
import { SearchResultDivider } from "./SearchBar";
import { UserSearchResultItem } from "./UserSearchResultItem";

type Props = {
  item:
    | UserSearchResult
    | OrgSearchResult
    | SearchResultDivider
    | RecentlySearchedValue;
  searchValue?: string;
};

const style = {
  divider: css({
    float: "left",
  }),
};

export const SearchResultItem = ({ item, searchValue }: Props) => {
  return (
    <>
      {item.type === "recently-searched" && (
        <RecentlySearchedItem item={item} />
      )}
      {item.type === "user" && (
        <UserSearchResultItem item={item} searchValue={searchValue} />
      )}
      {item.type === "org" && <OrgSearchResultItem item={item} />}
      {item.type === "divider" && (
        <div css={style.divider}>
          <Typography size="XS" weight="bold">
            {item.label}
          </Typography>
        </div>
      )}
    </>
  );
};
