import { ClockIcon } from "Icons/Clock";

import { css } from "@emotion/react";

import { Typography } from "components/shared/library/Typography";

import { uiGray } from "constants/colors";

const style = {
  wrapper: css({
    display: "flex",
    alignItems: "center",
    gap: 16,
  }),
};

export type RecentlySearchedValue = {
  name: string;
  label: string;
  type: "recently-searched";
};

type Props = {
  item: RecentlySearchedValue;
};

export const RecentlySearchedItem = ({ item }: Props) => {
  return (
    <div css={style.wrapper} data-testid="recently-searched-item">
      <ClockIcon stroke={uiGray[80]} />
      <Typography weight="medium" size="XS">
        {item.label}
      </Typography>
    </div>
  );
};
