import { IconType } from "Icons/types";

import { css } from "@emotion/react";

import { Badge } from "../library/Badge";

const style = {
  badge: css({ padding: "0px 7px 2px", whiteSpace: "nowrap" }),
};

type Props = { label: string; IconLeft?: IconType };

export const NavigationBadge = ({ label, IconLeft }: Props) => {
  return (
    <Badge
      label={label}
      color="purple"
      size="small"
      customCss={style.badge}
      IconLeft={IconLeft}
    />
  );
};
