import { IconProps } from "Icons/types";

import { white } from "constants/colors";

export const ShoppingBagIcon = ({
  width = 16,
  height = 14,
  stroke = white,
  fill = "none",
  strokeWidth = 1.75,
  strokeGradient,
  strokeGradientId,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    stroke={stroke}
    viewBox="0 0 16 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    {strokeGradient && strokeGradientId && strokeGradient()}
    <path
      d="M10.6664 4.33333C10.6664 5.04058 10.3854 5.71885 9.88535 6.21895C9.38525 6.71905 8.70697 7 7.99973 7C7.29249 7 6.61421 6.71905 6.11411 6.21895C5.61402 5.71885 5.33306 5.04058 5.33306 4.33333M2.42188 3.93426L1.95521 9.53425C1.85496 10.7373 1.80483 11.3388 2.00819 11.8028C2.18685 12.2105 2.49642 12.5469 2.88785 12.7588C3.33338 13 3.93697 13 5.14416 13H10.8553C12.0625 13 12.6661 13 13.1116 12.7588C13.503 12.5469 13.8126 12.2105 13.9913 11.8028C14.1946 11.3388 14.1445 10.7373 14.0443 9.53425L13.5776 3.93426C13.4913 2.89917 13.4482 2.38162 13.219 1.9899C13.0171 1.64496 12.7166 1.3684 12.3561 1.1959C11.9467 1 11.4273 1 10.3886 1L5.61082 1C4.57215 1 4.05281 1 3.64341 1.1959C3.28289 1.3684 2.98233 1.64496 2.78048 1.9899C2.55126 2.38162 2.50813 2.89917 2.42188 3.93426Z"
      strokeWidth={strokeWidth}
      stroke={
        strokeGradientId && strokeGradient
          ? `url(#${strokeGradientId})`
          : stroke
      }
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
