import { useEffect, useMemo, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { SelectPlanModal } from "components/recruit/SelectPlanModal";
import { Button, Props as ButtonProps } from "components/shared/library/Button";
import { Modal } from "components/shared/library/Modal";

import { getClientSecret } from "apis/recruit";
import { RecruitPlanName } from "apis/recruitingTeam";

type SelectablePlan = Exclude<RecruitPlanName, "Enterprise">;

export const UpgradePlanButton = ({
  defaultPlan,
  ...buttonProps
}: Omit<ButtonProps, "onClick"> & {
  defaultPlan?: SelectablePlan;
}) => {
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const [clientSecret, setClientSecret] = useState<string>();

  const stripePromise = useMemo(
    () => loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || ""),
    []
  );

  useEffect(() => {
    const get = async () => {
      const secret = await getClientSecret();
      setClientSecret(secret);
    };
    get();
  }, []);

  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => setIsSelectModalOpen(true)}
        disabled={!stripePromise || !clientSecret}
      />
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <SelectPlanModal
            isOpen={isSelectModalOpen}
            onClose={() => setIsSelectModalOpen(false)}
            onSuccessfullUpdate={() => {
              setIsSelectModalOpen(false);
              setIsSuccessModalOpen(true);
            }}
            defaultPlan={defaultPlan}
          />
        </Elements>
      )}
      <Modal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        title="Thank you for subscribing!"
        description="TO DO"
      />
    </>
  );
};
