import { IconProps } from "Icons/types";

import { uiGray } from "constants/colors";

export const InfinityIcon = ({
  width = 24,
  height = 24,
  stroke = uiGray[80],
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.12"
      d="M18.1782 8C23.2742 8 23.2742 16 18.1782 16C13.0832 16 11.0452 8 5.43924 8C0.854238 8 0.854238 16 5.43924 16C11.0452 16 13.0832 8 18.1792 8H18.1782Z"
      fill="none"
    />
    <path
      d="M18.1782 8C23.2742 8 23.2742 16 18.1782 16C13.0832 16 11.0452 8 5.43924 8C0.854238 8 0.854238 16 5.43924 16C11.0452 16 13.0832 8 18.1792 8H18.1782Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
