import { IconProps } from "Icons/types";

export const SearchIcon = ({
  width = 20,
  height = 20,
  stroke = "#FFFFFF",
  fill = "none",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 14L10.0001 10M11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
