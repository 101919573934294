import { css } from "@emotion/react";

import { HighlightedSubstring } from "components/shared/library/HighlightedSubstring";
import { Typography } from "components/shared/library/Typography";
import { UserAvatar } from "components/shared/library/UserAvatar";

import { UserSearchResult } from "apis/search";

import { getUserFullName } from "utils/accountProfile";
import { truncateStringAtTarget } from "utils/text";

import { uiGray } from "constants/colors";

const style = {
  wrapper: css({
    display: "flex",
    alignItems: "center",
    gap: 16,
  }),
  avatar: css({
    float: "right",
  }),
  ellipsis: css({
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }),
  content: css({
    overflow: "hidden",
  }),
  bio: css({
    color: uiGray[60],
  }),
};

type Props = {
  item: UserSearchResult;
  searchValue?: string;
};

export const UserSearchResultItem = ({ item, searchValue = "" }: Props) => {
  return (
    <div css={style.wrapper} data-testid="user-search-result-item">
      <div css={style.avatar}>
        {item.type === "user" && (
          <UserAvatar
            fileName={item.user.avatarUrl}
            handle={item.user.handle}
            frameShape={item.user.shape}
            isVerified={item.user.isVerified}
            size={32}
          />
        )}
      </div>
      <div css={style.content}>
        <Typography size="XS" tag="p" weight="medium">
          {getUserFullName(item.user)}
        </Typography>

        <HighlightedSubstring
          string={truncateStringAtTarget(item.user.headline, searchValue)}
          searchString={searchValue}
          customCss={{ wrapper: style.ellipsis, text: style.bio }}
        />
      </div>
    </div>
  );
};
