import { css } from "@emotion/react";

import { uiGray, white } from "constants/colors";

import { Typography } from "../library/Typography";

const style = {
  interviewArea: css({
    borderRadius: 16,
    background: white,
    display: "flex",
    margin: 16,
  }),
  innerContainer: css({
    padding: "16px 16px 24px 16px",
    width: "-webkit-fill-available",
  }),
  content: css({
    gap: 16,
    display: "flex",
    flexDirection: "column",
  }),
  description: css({
    gap: 8,
    display: "grid",
  }),
  divider: css({
    height: 0,
    border: `1px solid ${uiGray[10]}`,
  }),
};

export const InterviewRightPanel = () => {
  return (
    <div css={style.interviewArea}>
      <div css={style.innerContainer}>
        <div css={style.content}>
          <Typography size="M" weight="bold" color="black">
            How it works
          </Typography>
          <div css={style.description}>
            <Typography size="M" weight="medium">
              Start a practice round
            </Typography>
            <Typography color={uiGray[70]}>
              Upload your resume to get industry-specific questions tailored to
              you.
            </Typography>
          </div>
          <div css={style.divider} />
          <div css={style.description}>
            <Typography size="M" weight="medium">
              Get magic answers in a click
            </Typography>
            <Typography color={uiGray[70]}>
              Practice with suggested general or personalized answers.
            </Typography>
          </div>
          <div css={style.divider} />
          <div css={style.description}>
            <Typography size="M" weight="medium">
              Keep improving
            </Typography>
            <Typography color={uiGray[70]}>
              Learn from your practice rounds and build your confidence in a
              safe space.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
