import { UserInfo } from "apis/user";

export const splitFullName = (
  fullName: string
): { firstName: string; lastName: string } => {
  const _splitFullName = fullName.trim().split(" ");
  const firstName = _splitFullName[0];
  const lastName =
    _splitFullName.length === 1
      ? ""
      : _splitFullName.slice(1, _splitFullName.length).join(" ");
  return { firstName, lastName };
};

export const getUserFullName = (user: Partial<UserInfo> | null) => {
  if (!user) return "Deleted account";

  if (
    !user.fullName?.trim() &&
    !user.firstName?.trim() &&
    !user.lastName?.trim()
  )
    return "Someone";

  if (user.fullName?.trim()) return user.fullName;

  if (user.firstName?.trim() && user.lastName?.trim())
    return `${user.firstName} ${user.lastName}`;

  return user.firstName || user.lastName || "Someone";
};

export const getUserFirstName = (user: Partial<UserInfo> | null) => {
  if (!user) return "Deleted account";
  if (!user.firstName?.trim()) return "Someone";
  return user.firstName;
};

export const getFirstNameWithLastNameInitial = (
  user: Partial<UserInfo> | null
) => {
  if (!user) return "Deleted account";
  if (!user.firstName?.trim()) return "Someone";
  return `${user.firstName}${user.lastName ? ` ${user.lastName[0]}.` : ""}`;
};
