import { useRouter } from "next/router";

import { getOrgProfileUrl } from "utils/urls";

import { Avatar, AvatarProps } from "./Avatar";
import { ButtonWrapper } from "./ButtonWrapper";

type Props = {
  handle: string;
} & AvatarProps;

export const OrgAvatar = ({ handle, ...props }: Props) => {
  const router = useRouter();

  return (
    <ButtonWrapper
      onClick={() => {
        router.push(getOrgProfileUrl(handle));
      }}
      ariaLabel="View user profile"
    >
      <Avatar {...props} frameShape={"roundedSquare"} />
    </ButtonWrapper>
  );
};
