import { ChevronDownIcon } from "Icons/ChevronDown";
import { ChevronUpIcon } from "Icons/ChevronUp";
import { RefreshCw02Icon } from "Icons/RefreshCw02";

import { useRef } from "react";
import { CSSTransition } from "react-transition-group";

import { css } from "@emotion/react";

import { Authorized } from "components/shared/library/Authorized";
import { Avatar } from "components/shared/library/Avatar";
import { ButtonWrapper } from "components/shared/library/ButtonWrapper";
import { Divider } from "components/shared/library/Divider";
import { Typography } from "components/shared/library/Typography";

import { useAccount } from "contexts/AccountContext";
import { usePageLayoutContext } from "contexts/PageLayoutContext";

import { useActiveOrg } from "apis/accountProfile";

import {
  getOrgProfileUrl,
  getOrgSettingsUrl,
  getTeamDirectoryUrl,
} from "utils/urls";

import {
  SLIDE_TRANSITION_DURATION,
  SLIDE_TRANSITION_TIMING_FUNCTION,
} from "constants/animations";
import { uiGray } from "constants/colors";
import { placeholderOrgLogo } from "constants/images";

import { SidePanelItem } from "./SidePanelItem";
import { SidePanelTooltip } from "./SidePanelTooltip";

const style = {
  drawerItems: css({
    display: "flex",
    flexDirection: "column",
    paddingLeft: 35,
  }),
  drawerContent: css({ overflow: "hidden" }),
  menuLabel: css({
    flex: 1,
    whiteSpace: "nowrap",
  }),
  drawer: css({
    padding: "12px 0px",
    display: "flex",
    paddingLeft: 14,
    gap: 14,
    alignItems: "center",
  }),
  divider: css({
    margin: "12px 0",
  }),
  drawerCollapsed: css({
    paddingLeft: 7,
  }),
  switchOrg: css({
    display: "flex",
    alignItems: "center",
    paddingLeft: 14,
  }),
  logo: css({
    position: "relative",
  }),
  orgLogo: css({
    position: "absolute",
    top: -1,
    left: 5,
  }),
  wrapper: css({
    width: "100%",
    display: "contents",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    "& .collapsable-drawer-enter": {
      maxHeight: 0,
    },
    "& .collapsable-drawer-enter-active": {
      maxHeight: 400,
      transition: `max-height ${SLIDE_TRANSITION_DURATION} ${SLIDE_TRANSITION_TIMING_FUNCTION}`,
    },
    "& .collapsable-drawer-exit": {
      maxHeight: 400,
    },
    "& .collapsable-drawer-exit-active": {
      maxHeight: 0,
      transition: `max-height ${SLIDE_TRANSITION_DURATION} ${SLIDE_TRANSITION_TIMING_FUNCTION}`,
    },
  }),
};

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const SidePanelOrgDrawer = ({ isOpen, setIsOpen }: Props) => {
  const nodeRef = useRef(null);
  const { leftPanelStatus, setLeftPanelStatus } = usePageLayoutContext();
  const { accountProfile } = useAccount(true);
  const setActiveOrg = useActiveOrg(accountProfile?.id);

  if (!accountProfile.orgs.length) return null;
  return (
    <>
      <Divider customCss={style.divider} />
      <SidePanelTooltip label="My Org">
        <div css={style.wrapper}>
          <ButtonWrapper
            onClick={() => {
              if (leftPanelStatus === "collapsed") {
                setLeftPanelStatus("locked");
                setIsOpen(true);
              } else {
                setIsOpen(!isOpen);
              }
            }}
            customCss={[
              style.drawer,
              leftPanelStatus === "collapsed"
                ? style.drawerCollapsed
                : undefined,
            ]}
            ariaLabel={"Org Drawer"}
          >
            <Avatar
              fileName={
                accountProfile.orgs.find(
                  (org) => org.id === accountProfile.activeOrgId
                )?.photoUrl || placeholderOrgLogo
              }
              frameShape={"roundedSquare"}
              size={20}
            />
            {leftPanelStatus !== "collapsed" && (
              <>
                <Typography
                  color={isOpen ? uiGray[90] : uiGray[80]}
                  size="XS"
                  weight={isOpen ? "bold" : "medium"}
                  customCss={style.menuLabel}
                >
                  {accountProfile.orgs.find(
                    (org) => org.id === accountProfile.activeOrgId
                  )?.name || "My Org"}
                </Typography>
                {isOpen ? (
                  <ChevronUpIcon
                    stroke={uiGray[50]}
                    width={24}
                    height={24}
                    strokeWidth={1}
                  />
                ) : (
                  <ChevronDownIcon
                    stroke={uiGray[50]}
                    width={24}
                    height={24}
                    strokeWidth={1}
                  />
                )}
              </>
            )}
          </ButtonWrapper>
          <CSSTransition
            nodeRef={nodeRef}
            in={isOpen && leftPanelStatus !== "collapsed"}
            timeout={200}
            unmountOnExit
            classNames="collapsable-drawer"
          >
            <div ref={nodeRef} css={style.drawerContent}>
              {accountProfile.activeOrgId && (
                <div css={style.drawerItems}>
                  <SidePanelItem
                    label={"View org"}
                    link={getOrgProfileUrl(accountProfile.activeOrgId || "")}
                  />
                  <Authorized role="role-admin">
                    <SidePanelItem
                      label={"Team"}
                      link={getTeamDirectoryUrl()}
                    />
                  </Authorized>
                  <Authorized role="role-admin">
                    <SidePanelItem
                      label={"Settings"}
                      link={getOrgSettingsUrl()}
                    />
                  </Authorized>
                </div>
              )}
              {accountProfile.orgs
                .filter((org) => org.id !== accountProfile.activeOrgId)
                .map((org) => (
                  <div css={style.switchOrg} key={org.id}>
                    <div css={style.logo}>
                      <RefreshCw02Icon width={20} height={20} />
                      <div css={style.orgLogo}>
                        <Avatar
                          fileName={org.photoUrl || placeholderOrgLogo}
                          frameShape={"roundedSquare"}
                          size={10}
                        />
                      </div>
                    </div>

                    <ButtonWrapper onClick={() => setActiveOrg(org.id)}>
                      <SidePanelItem label={`Switch to ${org.name}`} />
                    </ButtonWrapper>
                  </div>
                ))}
            </div>
          </CSSTransition>
        </div>
      </SidePanelTooltip>
    </>
  );
};
