import { logger } from "interfaces/logger";

import { FrameShape } from "components/shared/library/Avatar";

import { supabase } from "apis/supabaseClient";

import { Org } from "./orgs";
import { ProfileColor } from "./posts";
import { UserInfo } from "./user";

export type OrgSearchResult = {
  name: string;
  label: string;
  org: Pick<Org, "id" | "photoUrl" | "bio" | "name" | "isVerified">;
  type: "org";
};

export type UserSearchResult = {
  name: string;
  label: string;
  user: Pick<
    UserInfo,
    | "id"
    | "avatarUrl"
    | "color"
    | "handle"
    | "headline"
    | "shape"
    | "fullName"
    | "isVerified"
  >;
  type: "user";
};

type SearchResult = {
  users: UserSearchResult[];
  totalUsers: number;
  orgs: OrgSearchResult[];
  totalOrgs: number;
};

type DBUserResult = {
  name: string;
  id: string;
  avatar_url: string;
  handle: string;
  bio: string;
  shape: FrameShape;
  is_verified: boolean;
  color: ProfileColor;
};

type DBOrgResult = {
  id: string;
  avatar_url: string;
  name: string;
  bio: string;
  is_verified: boolean;
};

export const searchAll = async (
  keywords: string,
  pageSize: number,
  userPageNumber: number,
  orgPageNumber: number
): Promise<SearchResult> => {
  const cleanKeywords = keywords
    .replace(/[^a-zA-Z0-9_ ]/g, "") // remove all special characters except spaces
    .replace(/\s\s+/g, " ") // remove all double spaces
    .trim(); // remove leading and trailing spaces

  const { data, error } = await supabase.rpc("search_all", {
    keywords: cleanKeywords,
    page_size: pageSize,
    user_page_number: userPageNumber,
    org_page_number: orgPageNumber,
  });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return {
    orgs: createOrgFromDBOrg(data[0].orgs) || [],
    totalOrgs: data[0].total_orgs,
    users: createUserFromDBUser(data[0].users) || [],
    totalUsers: data[0].total_users,
  };
};

const createOrgFromDBOrg = (dbOrgs: DBOrgResult[]): OrgSearchResult[] => {
  if (!dbOrgs) return [];
  const data: OrgSearchResult[] = dbOrgs.map((dbOrg: DBOrgResult) => {
    return {
      name: dbOrg.name,
      label: dbOrg.name,
      org: {
        id: dbOrg.id,
        photoUrl: dbOrg.avatar_url,
        bio: dbOrg.bio,
        name: dbOrg.name,
        isVerified: dbOrg.is_verified,
      },
      type: "org",
    };
  });
  return data;
};

const createUserFromDBUser = (dbUsers: DBUserResult[]): UserSearchResult[] => {
  if (!dbUsers) return [];
  const data: UserSearchResult[] = dbUsers.map((dbUser: DBUserResult) => {
    return {
      name: dbUser.name,
      label: dbUser.name,
      type: "user",
      user: {
        id: dbUser.id,
        avatarUrl: dbUser.avatar_url,
        handle: dbUser.handle,
        headline: dbUser.bio,
        shape: dbUser.shape,
        fullName: dbUser.name,
        isVerified: dbUser.is_verified,
        color: dbUser.color,
      },
    };
  });
  return data;
};

export const searchForMention = async (
  keywords: string,
  pageSize: number,
  pageNumber: number
): Promise<SearchResult> => {
  if (!keywords)
    return {
      orgs: [],
      totalOrgs: 0,
      users: [],
      totalUsers: 0,
    };
  const cleanKeywords = keywords
    .replace(/[^a-zA-Z0-9_ ]/g, "") // remove all special characters except spaces
    .replace(/\s\s+/g, " ") // remove all double spaces
    .trim(); // remove leading and trailing spaces

  const { data, error } = await supabase.rpc("search_mention", {
    keywords: cleanKeywords,
    page_size: pageSize,
    user_page_number: pageNumber,
    org_page_number: pageNumber,
  });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return {
    orgs: createOrgFromDBOrg(data[0].orgs) || [],
    totalOrgs: data[0].total_orgs,
    users: createUserFromDBUser(data[0].users) || [],
    totalUsers: data[0].total_users,
  };
};
