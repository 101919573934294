import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PostgrestError, PostgrestResponse } from "@supabase/supabase-js";
import { logger } from "interfaces/logger";
import { updateOrg } from "store/accountProfileSlice";
import { selectActiveOrgMembers, updateOrgMembers } from "store/orgMemberSlice";
import {
  selectOrgRolesAndLimits,
  updateOrgRolesAndLimits,
} from "store/orgRolesAndLimitsSlice";
import store from "store/store";

import { FrameShape } from "components/shared/library/Avatar";

import { useRealtimeChannelHealth } from "utils/realtime";

import { fetchAuthWrapper } from "./backend";
import { Role } from "./orgRoles";
import { ProfileColor } from "./posts";
import { supabase } from "./supabaseClient";
import { UserInfo } from "./user";

type OrgSetup = {
  userEmailForAutojoin: string | null;
  orgName: string;
  orgLogoUrl?: string;
  orgAdminEmails: string[];
};

type DbOrgRolesAndLimits = {
  org_id: string;
  roles: string[];
  tier_name: string;
};

export type OrgDirectoryUserInfo = Omit<UserInfo, "activeOrgId"> & {
  roles: Role[];
};

export type Org = {
  name: string;
  bio: string;
  photoUrl?: string;
  id: string;
  isVerified: boolean;
  isRegistered: boolean;
};

export const setupOrg = async ({
  userEmailForAutojoin,
  orgName,
  orgLogoUrl,
  orgAdminEmails,
}: OrgSetup): Promise<{ error: boolean }> => {
  const res = await fetchAuthWrapper.post("/be-api/setup-org", {
    userEmailForAutojoin,
    orgName,
    orgLogoUrl,
    orgAdminEmails,
  });

  return { error: res.status !== 200 };
};

export const createUnregisteredOrg = async (
  orgName: string
): Promise<{ error: boolean; data: { orgId: string } }> => {
  const res = await fetchAuthWrapper.post("/be-api/create-unregistered-org", {
    orgName,
  });

  const { body } = await res.json();

  return { error: res.status !== 200, data: { orgId: body.data.org_id } };
};

export type OrgMembers = {
  [index: string]: OrgDirectoryUserInfo[];
};

type DbUserOrgMembers = {
  id: string;
  user_avatar_url: string;
  user_color: string;
  first_name: string;
  last_name: string;
  user_name: string;
  user_shape: string;
  user_headline: string;
  user_handle: string;
  org_id: string;
  address: string;
  roles: Role[];
};

export const getUserOrgMembersByOrg = async (
  num: number | null
): Promise<OrgMembers> => {
  // this gets all common org members across all the user's orgs
  // the same user will appear multiple times if they are in multiple orgs
  const { data, error }: PostgrestResponse<DbUserOrgMembers> =
    await supabase.rpc("get_user_org_members", {
      num: num,
    });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  // we return all orgs the authed user is part of for each, all the users that are part of that org
  return data.reduce((orgMembers, dbUserOrgMember) => {
    const member: OrgDirectoryUserInfo = {
      id: dbUserOrgMember.id,
      avatarUrl: dbUserOrgMember.user_avatar_url,
      color: dbUserOrgMember.user_color as ProfileColor,
      fullName: dbUserOrgMember.user_name,
      firstName: dbUserOrgMember.first_name,
      lastName: dbUserOrgMember.last_name,
      shape: dbUserOrgMember.user_shape as FrameShape,
      headline: dbUserOrgMember.user_headline,
      handle: dbUserOrgMember.user_handle,
      email: dbUserOrgMember.address,
      roles: dbUserOrgMember.roles,
    };
    if (orgMembers[dbUserOrgMember.org_id]) {
      orgMembers[dbUserOrgMember.org_id].push(member);
    } else {
      orgMembers[dbUserOrgMember.org_id] = [member];
    }
    return orgMembers;
  }, {} as OrgMembers);
};

export const useOrgMembersRealtime = (
  userId?: string
): { isLoaded: boolean } => {
  const orgMembers = useSelector(selectActiveOrgMembers);
  const [isLoaded, setIsLoaded] = useState(!!Object.keys(orgMembers).length);

  const dispatch = useDispatch();

  const activeOrgChannelName = "db-active-org";
  const isChannelHealthy = useRealtimeChannelHealth(
    activeOrgChannelName,
    !!userId
  );

  useEffect(() => {
    if (!userId || (isLoaded && isChannelHealthy)) return;

    const channel = supabase.channel(activeOrgChannelName);

    // we listen to user_private_profiles because it contains activeOrgId
    // when a user switches active org, we refresh the user store
    // the assumption is that when a user switches org they might want
    // an updated list of org members
    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "user_private_profiles",
        filter: `id=eq.${userId}`,
      },
      async () => {
        const orgMembers = await getUserOrgMembersByOrg(null);
        dispatch(updateOrgMembers(orgMembers));
      }
    );

    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "org_roles",
        filter: `user_id=eq.${userId}`,
      },
      async () => {
        const orgMembers = await getUserOrgMembersByOrg(null);
        dispatch(updateOrgMembers(orgMembers));
      }
    );

    channel.on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "org_roles",
        filter: `user_id=eq.${userId}`,
      },
      async () => {
        const orgMembers = await getUserOrgMembersByOrg(null);
        dispatch(updateOrgMembers(orgMembers));
      }
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED" && !isLoaded) {
        const orgMembers = await getUserOrgMembersByOrg(null);
        dispatch(updateOrgMembers(orgMembers));
        setIsLoaded(true);
      }
    });
  }, [dispatch, userId, isLoaded, isChannelHealthy]);

  return { isLoaded };
};

export type OrgRolesAndLimits = {
  orgId: string;
  roles: Role[];
  tierName: string;
};

export const getUserOrgRolesAndLimits = async (): Promise<
  OrgRolesAndLimits[]
> => {
  const { data, error }: PostgrestResponse<DbOrgRolesAndLimits> =
    await supabase.rpc("get_org_roles_and_limits");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.map((dbOrgRolesAndLimits: DbOrgRolesAndLimits) => {
    const orgRolesAndLimits: OrgRolesAndLimits = {
      orgId: dbOrgRolesAndLimits.org_id,
      roles: dbOrgRolesAndLimits.roles as Role[],
      tierName: dbOrgRolesAndLimits.tier_name,
    };
    return orgRolesAndLimits;
  });
};

export const useOrgRolesAndLimitsRealtime = (
  userId: string | undefined,
  activeOrgId?: string
): {
  isLoaded: boolean;
  orgRolesAndLimits: Record<string, OrgRolesAndLimits>;
} => {
  const orgRoles = useSelector(selectOrgRolesAndLimits);
  const [isLoaded, setIsLoaded] = useState(!!Object.keys(orgRoles).length);
  const prevOrgId = useRef(activeOrgId);
  const prevuserId = useRef(userId);

  const dispatch = useDispatch();

  const orgRolesAndLimitsChannelName = "db-org-roles-and-limits";
  const isChannelHealthy = useRealtimeChannelHealth(
    orgRolesAndLimitsChannelName,
    !!userId
  );

  useEffect(() => {
    if (!userId) return;
    if (
      isChannelHealthy &&
      prevOrgId.current === activeOrgId &&
      prevuserId.current === userId
    )
      return;
    setIsLoaded(false);
    // if active org id changed, reset channel
    prevOrgId.current = activeOrgId;
    prevuserId.current = userId;
    const channel = supabase.channel(orgRolesAndLimitsChannelName);

    // update whenever userId switches activeOrgId
    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "user_private_profiles",
        filter: `id=eq.${userId}`,
      },
      async () => {
        const orgRolesAndLimits = await getUserOrgRolesAndLimits();
        dispatch(updateOrgRolesAndLimits(orgRolesAndLimits));
      }
    );

    // refetch on updated org_roles for userId
    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "org_roles",
        filter: `user_id=eq.${userId}`,
      },
      async () => {
        const orgRolesAndLimits = await getUserOrgRolesAndLimits();
        dispatch(updateOrgRolesAndLimits(orgRolesAndLimits));
      }
    );

    // refetch on new org roles for userId
    channel.on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "org_roles",
        filter: `user_id=eq.${userId}`,
      },
      async () => {
        const orgRolesAndLimits = await getUserOrgRolesAndLimits();
        dispatch(updateOrgRolesAndLimits(orgRolesAndLimits));
      }
    );

    // a new userId doesn't have an activeOrgId yet
    // so we need only sub here if activeOrgId is truthy
    if (activeOrgId) {
      // update on tier change for org (and any other org property change)
      channel.on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "orgs",
          filter: `id=eq.${activeOrgId}`,
        },
        async () => {
          const orgRolesAndLimits = await getUserOrgRolesAndLimits();
          dispatch(updateOrgRolesAndLimits(orgRolesAndLimits));
        }
      );
    }

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        const orgRolesAndLimits = await getUserOrgRolesAndLimits();
        dispatch(updateOrgRolesAndLimits(orgRolesAndLimits));
        // if the active org id is undefined, we are not loaded
        setIsLoaded(true);
      }
    });
  }, [dispatch, userId, activeOrgId, isChannelHealthy]);

  return { orgRolesAndLimits: orgRoles, isLoaded };
};

export const getOrg = async (
  orgId: string
): Promise<Pick<
  Org,
  "bio" | "id" | "name" | "photoUrl" | "isVerified"
> | null> => {
  const { data, error } = await supabase
    .from("orgs")
    .select("name,bio,photo_url,id,is_verified")
    .eq("id", orgId);
  if (!error) {
    const org = data[0];
    return {
      ...org,
      photoUrl: org.photo_url,
      isVerified: org.is_verified,
    };
  }
  return null;
};

export const updateOrgPreferences = async (
  preferences: Pick<Org, "id" | "name" | "bio" | "photoUrl" | "isVerified">
): Promise<{ error: PostgrestError | null | string }> => {
  if (preferences.id) {
    const { error } = await supabase
      .from("orgs")
      .update({
        name: preferences.name,
        bio: preferences.bio,
        photo_url: preferences.photoUrl,
      })
      .eq("id", preferences.id);

    // update the store to reflect the new org name
    store.dispatch(
      updateOrg({
        id: preferences.id,
        name: preferences.name,
        photoUrl: preferences.photoUrl,
        isVerified: preferences.isVerified,
      })
    );
    return { error };
  }

  return { error: "undefined orgId" };
};

export const findRegisteredOrgsMatchingName = async (
  name: string,
  limit = 5
): Promise<Pick<Org, "id" | "name" | "photoUrl" | "isVerified">[]> => {
  const { data } = await supabase
    .from("orgs")
    .select("*")
    .ilike("name", `%${name}%`)
    .eq("is_registered", true)
    .order("is_verified", { ascending: false })
    .limit(limit);

  if (!data) {
    return [];
  }

  return data.map((org) => ({
    id: org.id,
    name: org.name,
    photoUrl: org.photo_url,
    isVerified: org.is_verified,
  }));
};
