import { IconType } from "Icons/types";

import { css } from "@emotion/react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

import Link from "next/link";
import { useRouter } from "next/router";

import { Typography } from "components/shared/library/Typography";

import { usePageLayoutContext } from "contexts/PageLayoutContext";

import { purple, uiGray } from "constants/colors";

const style = {
  menuItem: css({
    margin: "0 36px",
    padding: "9px 12px",
    width: "-webkit-fill-available",
    marginTop: 8,
  }),
  iconSpan: css({ display: "flex", paddingRight: 14 }),
  iconSpanIconOnly: css({
    paddingRight: 0,
  }),
  link: css({ textDecoration: "none", width: "100%" }),
  item: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  menuLabel: css({
    flex: 1,
    whiteSpace: "nowrap",
    color: uiGray[90],
  }),
  menuLabelActive: css({
    flex: 1,
    whiteSpace: "nowrap",
    color: purple[800],
  }),
  wrapper: css({
    padding: "8px 12px 8px 12px",
    paddingLeft: 14,
    borderRadius: 8,
  }),
  iconOnlyWrapper: css({
    padding: "8px 0px",
    paddingLeft: 0,
  }),
  active: css({
    background: purple[100],
  }),
};

export type Props = {
  label: string;
  Icon?: IconType;
  link?: string;
  RightComponent?: EmotionJSX.Element;
  iconOnly?: boolean;
  disableChildPathAsActive?: boolean;
};

const Item = ({
  Icon,
  label,
  isActive = false,
  RightComponent,
  iconOnly = false,
}: Props & { isActive?: boolean }) => {
  return (
    <div css={style.item}>
      {Icon && (
        <span
          css={[style.iconSpan, iconOnly ? style.iconSpanIconOnly : undefined]}
          data-testid="menu-item-icon"
        >
          <Icon
            stroke={isActive ? purple[800] : uiGray[70]}
            fill={isActive ? "rgba(81, 45, 255, 0.12)" : "none"}
            width={20}
            height={20}
          />
        </span>
      )}
      {!iconOnly && (
        <>
          <Typography
            color={isActive ? uiGray[90] : uiGray[80]}
            size="XS"
            weight={isActive ? "bold" : "medium"}
            customCss={isActive ? style.menuLabelActive : style.menuLabel}
          >
            {label}
          </Typography>
          {RightComponent}
        </>
      )}
    </div>
  );
};

export const MenuItem = ({
  label,
  link,
  Icon,
  RightComponent,
  iconOnly = false,
  disableChildPathAsActive,
}: Props) => {
  const router = useRouter();
  const { leftPanelStatus, setLeftPanelStatus } = usePageLayoutContext();

  const isActive =
    (!disableChildPathAsActive && link && router.asPath.includes(link)) ||
    router.asPath === link;

  return (
    <div
      css={[
        style.wrapper,
        iconOnly ? style.iconOnlyWrapper : undefined,
        isActive ? style.active : undefined,
      ]}
    >
      {link ? (
        <Link
          href={link}
          css={style.link}
          onClick={() => {
            if (leftPanelStatus === "preview") {
              setLeftPanelStatus("collapsed");
            }
          }}
        >
          <Item
            Icon={Icon}
            label={label}
            isActive={isActive}
            RightComponent={RightComponent}
            iconOnly={iconOnly}
          />
        </Link>
      ) : (
        <Item
          Icon={Icon}
          label={label}
          RightComponent={RightComponent}
          iconOnly={iconOnly}
        />
      )}
    </div>
  );
};
