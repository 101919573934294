import { useState } from "react";

import { SerializedStyles } from "@emotion/react";

import { Drawer } from "./Drawer";

type Props = {
  children: React.ReactNode;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  initallyExpanded?: boolean;
  customCss?: { wrapper?: SerializedStyles; children?: SerializedStyles };
};

export const CollapsibleSection = ({
  title,
  children,
  description,
  initallyExpanded = false,
  customCss,
}: Props) => {
  const [isCollapsible, setIsCollapsible] = useState(initallyExpanded);

  return (
    <Drawer
      title={title}
      description={description}
      isOpen={isCollapsible}
      onClick={setIsCollapsible}
      customCss={customCss}
    >
      {children}
    </Drawer>
  );
};
