import { CheckCircleIcon } from "Icons/CheckCircle";
import { keyBy } from "lodash";

import { useMemo } from "react";

import { css } from "@emotion/react";

import { Badge } from "components/shared/library/Badge";
import { CollapsibleSection } from "components/shared/library/CollapsibleSection";
import { Typography } from "components/shared/library/Typography";

import {
  RecruitPlanName,
  RecruitPrice,
  useRecruitPlanRealtime,
} from "apis/recruitingTeam";

import { formatAmountToDollars } from "utils/earnings";
import { getRecruitPriceFeatures } from "utils/recruit";

import { purple, uiGray } from "constants/colors";

const style = {
  wrapper: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
    borderRadius: 12,
    border: `1px solid ${uiGray[10]}`,
    padding: 16,
  }),
  top: css({
    display: "flex",
    justifyContent: "space-between",
    gap: 16,
  }),
  title: css({
    display: "flex",
    gap: 8,
  }),
  info: css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }),
  price: css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
    alignItems: "flex-end",
  }),

  collapsible: css({
    padding: "8px 16px",
    background: purple[50],
    border: "none",
  }),
  collapsibleContent: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
  bullet: css({
    display: "flex",
    gap: 8,
  }),
};

export const RecruitPlanDetail = ({
  planName,
  prices,
}: {
  planName: Exclude<RecruitPlanName, "Enterprise">;
  prices: RecruitPrice[];
}) => {
  const { plan: currentPlan } = useRecruitPlanRealtime();
  const pricesByPlan = useMemo(() => keyBy(prices, "planName"), [prices]);

  // user would be blocked off if there were no plans
  if (!currentPlan) return <></>;

  const selectedPlan = pricesByPlan[planName];
  // the plan right below the selected plan
  const lowerPlan = {
    Starter: null,
    Growth: pricesByPlan.Starter,
    Business: pricesByPlan.Growth,
  }[planName];

  // the savings user would make per interview with selected plan compared to current plan
  const savingPerInterview =
    currentPlan.usageBasedUnitAmountDecimal -
    selectedPlan.usageBasedUnitAmountDecimal;

  return (
    <div css={style.wrapper}>
      <div css={style.top}>
        <div css={style.info}>
          <Typography size="M" weight="bold" customCss={style.title}>
            {planName}{" "}
            {savingPerInterview > 0 && (
              <Badge
                color="red"
                label={`Save ${formatAmountToDollars(
                  savingPerInterview
                )} per interview`}
              />
            )}
          </Typography>
          <Typography size="XXS" color={uiGray[70]}>
            {
              {
                Starter: "For independent recruiters",
                Growth: "For small but mighty teams",
                Business: "For large teams scaling fast",
              }[planName]
            }
          </Typography>
        </div>
        <div css={style.price}>
          <Typography size="M" weight="bold" color={purple[600]}>
            {`${formatAmountToDollars(selectedPlan.planUnitAmountDecimal)} USD`}
          </Typography>
          <Typography size="XXS" color={uiGray[70]}>
            / {selectedPlan.planRecurringInterval}
          </Typography>
        </div>
      </div>
      <CollapsibleSection
        title="What features are included:"
        customCss={{
          wrapper: style.collapsible,
          children: style.collapsibleContent,
        }}
      >
        {getRecruitPriceFeatures(selectedPlan, lowerPlan).map((feature) => (
          <div css={style.bullet} key={feature}>
            <CheckCircleIcon width={16} height={16} stroke={purple[600]} />
            <Typography size="XXS">{feature}</Typography>
          </div>
        ))}
      </CollapsibleSection>
    </div>
  );
};
