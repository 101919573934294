import { css } from "@emotion/react";

import { Typography } from "components/shared/library/Typography";

import { uiGray, white } from "constants/colors";

const style = {
  chatArea: css({
    borderRadius: 16,
    background: white,
    display: "flex",
    margin: 16,
  }),
  innerContainer: css({
    padding: "16px 16px 24px 16px",
    width: "-webkit-fill-available",
  }),
  content: css({
    gap: 16,
    display: "flex",
    flexDirection: "column",
  }),
  description: css({
    gap: 8,
    display: "grid",
  }),
  divider: css({
    height: 0,
    border: `1px solid ${uiGray[10]}`,
  }),
};

export const JobsCopilotRightPanel = () => {
  return (
    <div css={style.chatArea}>
      <div css={style.innerContainer}>
        <div css={style.content}>
          <Typography size="M" weight="bold" color="black">
            How it works
          </Typography>
          <div css={style.description}>
            <Typography size="M" weight="medium">
              Download Copilot
            </Typography>
            <Typography color={uiGray[70]}>
              Head over to the Chrome Store on your browser and get extension.
            </Typography>
          </div>
          <div css={style.divider} />
          <div css={style.description}>
            <Typography size="M" weight="medium">
              View jobs on different platforms
            </Typography>
            <Typography color={uiGray[70]}>
              Works on most job boards like LinkedIn, Indeed and Lever postings.
            </Typography>
          </div>
          <div css={style.divider} />
          <div css={style.description}>
            <Typography size="M" weight="medium">
              Save, apply and keep track
            </Typography>
            <Typography color={uiGray[70]}>
              Easily save the jobs you viewed and stay on top of of your job
              search.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
