import { Fragment } from "react";

import { css } from "@emotion/react";

import { useAuth } from "contexts/authContext";

import { useIsFollowing } from "apis/follow";
import { UserInfo } from "apis/user";

import { Divider } from "./Divider";
import { UserListItem } from "./UserListItem";

const style = {
  wrapper: css({
    display: "grid",
    gap: 12,
  }),
  divider: css({
    margin: 0,
  }),
};

type Props = {
  users: (Partial<
    Pick<
      UserInfo,
      "id" | "handle" | "fullName" | "shape" | "avatarUrl" | "headline"
    >
  > & { addressId?: string })[];
  dividerEnabled?: boolean;
  onDelete?: (userId: string) => void;
};

export const UserList = ({
  users,
  dividerEnabled = false,
  onDelete,
}: Props) => {
  const { user: authUser } = useAuth();

  const usersWithIds = users?.filter((u) => u.id !== undefined);

  useIsFollowing(
    authUser?.id,
    usersWithIds?.map((u) => u.id as string)
  );

  return (
    <div css={style.wrapper}>
      {users.map((user) => (
        <Fragment key={user.id}>
          <UserListItem
            user={user}
            onDelete={onDelete && (() => onDelete(user.id as string))}
          />
          {dividerEnabled && <Divider customCss={style.divider} />}
        </Fragment>
      ))}
    </div>
  );
};
