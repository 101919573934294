import { logger } from "interfaces/logger";

import { FrameShape } from "components/shared/library/Avatar";

import { UserInfo } from "apis/user";

import { supabase } from "./supabaseClient";

type DbUserRecommendation = {
  id: string;
  avatar_url: string;
  color: string;
  full_name: string;
  shape: FrameShape;
  headline: string;
  handle: string;
};

export const getFollowRecommendations = async (): Promise<
  Pick<
    UserInfo,
    "id" | "handle" | "fullName" | "shape" | "avatarUrl" | "headline"
  >[]
> => {
  const { data, error } = await supabase.rpc("get_follow_recommendations");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.map((user: DbUserRecommendation) => ({
    id: user.id,
    avatarUrl: user.avatar_url,
    color: user.color,
    fullName: user.full_name,
    shape: user.shape,
    headline: user.headline,
    handle: user.handle,
  }));
};
