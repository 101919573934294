import { LinkExternal02 } from "Icons/LinkExternal02";
import { keyBy } from "lodash";

import { useCallback, useMemo, useState } from "react";

import { css } from "@emotion/react";

import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useRouter } from "next/router";

import { Dropdown } from "components/shared/library/Dropdown";
import { Modal } from "components/shared/library/Modal";

import { useAlert } from "contexts/alertContext";

import { updateRecruitingPlan } from "apis/recruit";
import {
  RecruitPlanName,
  useRecruitCatalogPrices,
  useRecruitPlanRealtime,
} from "apis/recruitingTeam";

import { formatAmountToDollars } from "utils/earnings";
import { getExternalPath, getExternalPricingUrl } from "utils/urls";

import { RecruitPlanDetail } from "./RecruitPlanDetail";

type SelectablePlan = Exclude<RecruitPlanName, "Enterprise">;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccessfullUpdate: () => void;
  defaultPlan?: SelectablePlan;
};

const style = {
  wrapper: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
};

export const SelectPlanModal = ({
  onClose,
  isOpen,
  onSuccessfullUpdate,
  defaultPlan,
}: Props) => {
  const { plan } = useRecruitPlanRealtime();
  const currentPlan = plan?.planName as SelectablePlan;
  const [selectedPlan, setSelectedPlan] = useState<SelectablePlan>(
    defaultPlan || currentPlan || "Growth"
  );
  const [step, setStep] = useState<"select" | "payment">("select");
  const stripe = useStripe();
  const elements = useElements();
  const { createAlert } = useAlert();
  const router = useRouter();
  const { prices, isLoaded: isPricesLoaded } = useRecruitCatalogPrices();
  const pricesByPlan = useMemo(() => keyBy(prices, "planName"), [prices]);

  const plans = useMemo(
    () =>
      !isPricesLoaded
        ? []
        : ["Starter", "Growth", "Business"].map((plan) => ({
            name: plan,
            label: `${plan} plan - ${formatAmountToDollars(
              pricesByPlan[plan].planUnitAmountDecimal
            )}/${pricesByPlan[plan].planRecurringInterval} ${
              currentPlan === plan ? "(Current)" : ""
            }`,
          })),
    [isPricesLoaded, pricesByPlan, currentPlan]
  );

  const updatePlan = useCallback(async () => {
    const { error } = await updateRecruitingPlan(selectedPlan);
    if (error) {
      createAlert("error", error);
    } else {
      onSuccessfullUpdate();
    }
  }, [createAlert, onSuccessfullUpdate, selectedPlan]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        currentPlan
          ? "Upgrade your plan"
          : `Your ${pricesByPlan.Starter.trialPeriodDays}-day free trial ended`
      }
      description="Identify top talent faster with our flexible plans."
      leftButtonLabel={step == "select" ? "Compare plans" : "Back"}
      leftButtonOnClick={() => {
        if (step === "select" && window)
          window.open(getExternalPricingUrl(), "_blank");
        if (step === "payment") setStep("select");
      }}
      leftButtonIconLeft={step == "select" ? LinkExternal02 : undefined}
      confirmLabel={step === "select" ? "Choose plan" : "Confirm payment"}
      onConfirm={async (event) => {
        event.preventDefault();
        if (step === "select") {
          if (!plan?.hasPaymentMethod) {
            setStep("payment");
          } else {
            updatePlan();
          }
        }
        if (step === "payment") {
          if (!stripe || !elements) return;
          const { error, setupIntent } = await stripe.confirmSetup({
            elements,
            confirmParams: {
              return_url: getExternalPath(router.asPath),
            },
            redirect: "if_required",
          });

          if (error?.message) {
            createAlert("error", error.message);
          } else if (setupIntent && setupIntent.status === "succeeded") {
            updatePlan();
          }
        }
      }}
      isConfirmDisabled={currentPlan === selectedPlan || !stripe || !elements}
      customCss={{ content: style.wrapper }}
    >
      {step === "select" && (
        <Dropdown
          onChange={(value) =>
            value && setSelectedPlan(value as SelectablePlan)
          }
          options={plans}
          label={"Choose a plan"}
          defaultValue={selectedPlan}
          placeholder="Select a job posting to connect with this interview"
        />
      )}

      {step === "select" && selectedPlan && prices && (
        <RecruitPlanDetail planName={selectedPlan} prices={prices} />
      )}
      {step === "payment" && <PaymentElement />}
    </Modal>
  );
};
