import { css } from "@emotion/react";

import { Typography } from "components/shared/library/Typography";

import { uiGray, white } from "constants/colors";

const style = {
  wrapper: css({
    borderRadius: 16,
    background: white,
    display: "flex",
    margin: 16,
  }),
  innerContainer: css({
    padding: "16px 16px 24px 16px",
    width: "-webkit-fill-available",
  }),
  content: css({
    gap: 16,
    display: "flex",
    flexDirection: "column",
  }),
  description: css({
    gap: 8,
    display: "grid",
  }),
  divider: css({
    height: 0,
    border: `1px solid ${uiGray[10]}`,
  }),
};

export const DocumentsRightPanel = () => {
  return (
    <div css={style.wrapper}>
      <div css={style.innerContainer}>
        <div css={style.content}>
          <Typography size="M" weight="bold" color="black">
            Some things you should know
          </Typography>
          <div css={style.description}>
            <Typography size="M" weight="medium">
              Ribbon gets better with context
            </Typography>
            <Typography color={uiGray[70]}>
              {
                "Keeping your documents updated improves and better tailors Ribbon's Career Kit and Chat AI features. "
              }
            </Typography>
          </div>
          <div css={style.divider} />
          <div css={style.description}>
            <Typography size="M" weight="medium">
              Your documents are safe with us
            </Typography>
            <Typography color={uiGray[70]}>
              {"We won't share your personal files with anyone else."}
            </Typography>
          </div>
          <div css={style.divider} />
          <div css={style.description}>
            <Typography size="M" weight="medium">
              All your documents in one place
            </Typography>
            <Typography color={uiGray[70]}>
              {
                "You can upload as many documents as you'd like. Ribbon will use the latest files to keep our advice and responses personal. "
              }
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
