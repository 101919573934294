import { useEffect, useState } from "react";

import { css } from "@emotion/react";

import { useAuth } from "contexts/authContext";

import { useIsFollowing } from "apis/follow";
import { getFollowRecommendations } from "apis/recommendations";
import { UserInfo } from "apis/user";

import { uiGray } from "constants/colors";

import { Button } from "../library/Button";
import { Typography } from "../library/Typography";
import { UserList } from "../library/UserList";

const style = {
  description: css({
    marginTop: 16,
  }),
  recommendationArea: css({
    paddingLeft: 16,
    paddingRight: 16,
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
  showMore: css({
    alignSelf: "flex-start",
  }),
};

export const Recommendations = () => {
  const [users, setUsers] =
    useState<
      Pick<
        UserInfo,
        "id" | "handle" | "fullName" | "shape" | "avatarUrl" | "headline"
      >[]
    >();

  const { user } = useAuth();

  useEffect(() => {
    const get = async () => {
      const users = await getFollowRecommendations();
      setUsers(users);
    };
    get();
  }, []);

  useIsFollowing(
    user?.id,
    users?.map((u) => u.id)
  );

  return (
    <div css={style.recommendationArea}>
      <Typography
        customCss={style.description}
        size="XS"
        color={uiGray[70]}
        tag="p"
      >
        People you may know
      </Typography>

      {users && users.length > 0 && <UserList users={users} dividerEnabled />}

      <Button
        background="ghost"
        color="gray"
        onClick={async () => {
          const users = await getFollowRecommendations();
          setUsers(users);
        }}
        size="small"
      >
        Show more
      </Button>
    </div>
  );
};
