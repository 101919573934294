import React, { FocusEvent, useRef } from "react";

import { SerializedStyles, css } from "@emotion/react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

import { useMaxChar } from "utils/libraryHooks";

import { purple, red, uiGray, white } from "constants/colors";

import { FieldLabel } from "./FieldLabel";
import { HelpText } from "./HelpText";
import { MaxCharCounter } from "./MaxCharCounter";

type Props = {
  onChange: (value: string) => void;
  value?: string | number;
  label: string;
  placeholder?: string;
  isError?: boolean;
  isDisabled?: boolean;
  displayLabel?: boolean;
  type?: "text" | "password" | "number" | "email";
  onEnter?: () => void;
  onBackspace?: () => void;
  onFocus?: (e: FocusEvent<HTMLDivElement, Element> | undefined) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement, Element>) => void;
  minValue?: number;
  maxValue?: number;
  maxChar?: number;
  isOptional?: boolean;
  customCss?: {
    outerWrapper?: SerializedStyles;
    wrapper?: SerializedStyles;
    input?: SerializedStyles;
  };
  PreTabComponents?: EmotionJSX.Element[]; // a list of components that are before, and in the same flow as the search text
  SuffixComponent?: EmotionJSX.Element; // a component that is static, after the search text
  inputRef?: React.RefObject<HTMLInputElement>;
  helpText?: string | React.ReactNode;
};

const style = {
  wrapper: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 14px",
    background: white,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: uiGray[10],
    borderRadius: 12,
    "&:focus-within": {
      outline: `1px solid ${purple[600]}`,
      boxShadow: `0px 0px 0px 4px ${purple.focusShadow}`,
    },
    "&:disabled-within": {
      color: uiGray[50],
      background: "rgba(141, 141, 141, 0.05)",
      borderColor: "#ECECEC",
    },
  }),
  disabled: css({
    color: uiGray[50],
    background: "rgba(141, 141, 141, 0.05)",
    borderColor: "#ECECEC",
  }),
  content: css({
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 8,
  }),
  label: css({ marginBottom: 8 }),
  input: css({
    border: "none",
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: uiGray[60],
    },

    fontWeight: 400,
    fontSize: 16,
    lineHeight: "20px",
    color: uiGray[100],
    flex: 1,
  }),
  error: css({
    outline: `1px solid ${red[600]}!important`,
    boxShadow: `0px 0px 0px 4px ${red.focusError}!important`,
  }),
  helpers: css({ display: "flex", justifyContent: "space-between" }),
  helpTextWrapper: css({ flex: 1 }),
  helpText: css({ marginTop: 6, marginLeft: 4 }),
};

export const Input = ({
  onChange,
  value,
  label,
  placeholder = "",
  isDisabled,
  isError,
  displayLabel = false,
  type = "text",
  onEnter,
  onBackspace,
  onBlur,
  minValue,
  maxValue,
  maxChar,
  isOptional = false,
  onFocus,
  customCss,
  PreTabComponents,
  SuffixComponent,
  inputRef: inputRefProp,
  helpText,
}: Props) => {
  const [maxCharExceeded, setMaxCharExceeded] = useMaxChar();
  const _inputRef = useRef<HTMLInputElement>(null);
  const inputRef = inputRefProp || _inputRef;

  return (
    <div css={[customCss?.outerWrapper]}>
      {displayLabel && <FieldLabel label={label} isOptional={isOptional} />}
      <div
        data-testid="input-wrapper"
        css={[
          style.wrapper,
          (isError || maxCharExceeded) && style.error,
          customCss?.wrapper,
          isDisabled ? style.disabled : undefined,
        ]}
        tabIndex={isDisabled ? undefined : 0}
        onFocus={(e) => {
          inputRef.current?.focus();
          if (onFocus) onFocus(e);
        }}
      >
        <div css={style.content}>
          {PreTabComponents &&
            PreTabComponents.map((PreTabComponent) => PreTabComponent)}
          <input
            ref={inputRef}
            autoComplete="off"
            role="textbox"
            aria-label={label}
            disabled={isDisabled}
            id={`${label}-input`}
            placeholder={placeholder}
            css={[style.input, customCss?.input]}
            onChange={(e) => {
              onChange(e.target.value.slice(0, maxChar));
              setMaxCharExceeded(e.target.value.length > (maxChar || Infinity));
            }}
            onBlur={onBlur}
            value={value}
            type={type}
            onKeyUp={(e) => {
              if (e.key === "Enter" && onEnter) {
                onEnter();
              }
            }}
            onKeyDown={(e) => {
              // onBackspace is triggered on key down, as we want it to trigger _before_ it deleted the input text
              if (e.key === "Backspace" && onBackspace) {
                onBackspace();
              }
            }}
            min={minValue}
            max={maxValue}
          ></input>
        </div>
        {SuffixComponent}
      </div>
      <div css={style.helpers}>
        <div css={style.helpTextWrapper}>
          <HelpText
            size="small"
            customCss={style.helpText}
            color={isError ? red[600] : uiGray[90]}
          >
            {helpText || null}
          </HelpText>
        </div>
        {maxChar && typeof value == "string" && (
          <MaxCharCounter maxChar={maxChar} value={value} />
        )}
      </div>
    </div>
  );
};
