import { SerializedStyles, css } from "@emotion/react";

import { purple } from "constants/colors";

const style = {
  highlight: css({
    fontWeight: 700,
    color: purple[600],
  }),
};

type Props = {
  string: string;
  searchString?: string;
  customCss?: { wrapper?: SerializedStyles; text?: SerializedStyles };
};

export const HighlightedSubstring = ({
  string,
  searchString,
  customCss,
}: Props) => {
  if (!searchString) return <span>{string}</span>;

  // grab all the indexes where we encounter the searchString comparing lowercase to lowercase
  const occurrenceIndices = string
    .toLowerCase()
    // we split the string by the search string, only substrings that are in between the search string will be left in an array
    .split(searchString.toLowerCase())
    .reduce((occurrenceIndices, currentString, idx) => {
      if (idx === 0) {
        // the first occurrence is at the index of the current string length
        // i.e if current string is "", index of first occurrence of substring in string is 0
        // i.e if current string is "The ", index of first occurrence of substring in string is 4 (after current string)
        occurrenceIndices.push(currentString.length);
      } else {
        // the position of the last occurrence of the search string in the string
        const lastIndex = occurrenceIndices[occurrenceIndices.length - 1];
        // the new index is after the last index and the length og the search string + the length of the current string
        const newIndex = lastIndex + searchString.length + currentString.length;
        occurrenceIndices.push(newIndex);
      }
      return occurrenceIndices;
    }, [] as number[]);

  return (
    <div css={customCss?.wrapper}>
      {occurrenceIndices.map((idx, i) => (
        <span key={idx} css={customCss?.text}>
          {!i && !!idx && string.substring(0, idx)}
          <span css={style.highlight}>
            {string.substring(idx, idx + searchString.length)}
          </span>
          {string.substring(
            idx + searchString.length,
            occurrenceIndices[i + 1]
          )}
        </span>
      ))}
    </div>
  );
};
