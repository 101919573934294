import { CheckColorIcon } from "Icons/CheckColor";

import { useRouter } from "next/router";

import { getProfileUrl } from "utils/urls";

import { Avatar, AvatarProps } from "./Avatar";
import { ButtonWrapper } from "./ButtonWrapper";

type Props = {
  handle?: string;
  isVerified?: boolean;
} & AvatarProps;

export const UserAvatar = ({ handle, isVerified, ...props }: Props) => {
  const router = useRouter();

  return (
    <ButtonWrapper
      onClick={() => {
        if (handle) router.push(getProfileUrl(handle));
      }}
      ariaLabel="View user profile"
    >
      <Avatar {...props} Icon={isVerified ? CheckColorIcon : undefined} />
    </ButtonWrapper>
  );
};
