import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { logger } from "interfaces/logger";
import {
  addNotification,
  addNotifications,
  markAsActionTaken,
  markAsViewed,
  removeNotification,
} from "store/notificationsSlice";
import store from "store/store";

import { FrameShape } from "components/shared/library/Avatar";

import { useRealtimeChannelHealth } from "utils/realtime";

import { DbExperience } from "./modules";
import { supabase } from "./supabaseClient";
import { UserInfo } from "./user";

export type Notification =
  | PostCommentNotif
  | PostLikeNotif
  | NewFollowerNotif
  | PostCommentReplyNotif
  | ProfileViewNotif
  | IdVerificationFailedNotif
  | MentionedUserNotif;

export type NotificationType =
  | "post-comment"
  | "post-like"
  | "new-follower"
  | "post-comment-reply"
  | "profile-views"
  | "id-verification-fail"
  | "mentioned-user";

type GenericNotif = {
  id: string;
  actionRequired: boolean;
  viewedAt: string | null;
  createdAt: string;
};

export type MentionedUserNotif = GenericNotif & {
  notificationType: "mentioned-user";
  mentioner: Pick<UserInfo, "fullName"> | null;
  postId: string;
  type: "post" | "comment";
};

export type IdVerificationFailedNotif = GenericNotif & {
  notificationType: "id-verification-fail";
};

export type PostCommentNotif = GenericNotif & {
  notificationType: "post-comment";
  commenter: Pick<UserInfo, "fullName"> | null;
  postId: string;
};

export type PostCommentReplyNotif = GenericNotif & {
  notificationType: "post-comment-reply";
  commenter: Pick<UserInfo, "fullName"> | null;
  postId: string;
};

export type PostLikeNotif = GenericNotif & {
  notificationType: "post-like";
  likers: (Pick<UserInfo, "fullName"> & { created_at: string })[];
  postId: string;
};

export type NewFollowerNotif = GenericNotif & {
  notificationType: "new-follower";
  follower: Pick<UserInfo, "fullName" | "handle">;
};

export type ProfileViewNotif = GenericNotif & {
  notificationType: "profile-views";
  views: {
    viewer: Pick<
      UserInfo,
      "id" | "fullName" | "handle" | "avatarUrl" | "headline" | "shape"
    >;
    createdAt: string;
  }[];
};

type DbNotification = {
  id: string;
  action_required: boolean;
  viewed_at: string;
  created_at: string;
  notification_type: NotificationType;
  // experience_verif_request_notif stuffs
  experience_verif_request_requester_full_name: string;
  experience_verif_request_requester_handle: string;
  experience_verif_request_text: string;
  experience: DbExperience;
  // post_comment_notification stuffs
  post_comment_notification_commenter_full_name: string;
  post_comment_notification_post_id: string;
  // post_like_notification stuffs
  post_like_notification_likers: {
    liker_full_name: string;
    created_at: string;
  }[];
  post_like_notification_post_id: string;
  // new follower stuffs
  new_follower_notification_follower_full_name: string;
  new_follower_notification_follower_handle: string;
  // -- profile_view_notification stuffs
  profile_view_notification_profile_views: {
    viewer_user_id: string;
    viewer_full_name: string;
    viewer_handle: string;
    viewer_photo_url: string;
    viewer_headline: string;
    viewer_shape: string;
    created_at: string;
  }[];
  // -- experience_verification stuffs
  experience_verif_accepted_verifier_user_id: string;
  experience_verif_accepted_verifier_full_name: string;
  experience_verif_accepted_verifier_handle: string;
  experience_verif_accepted_experience: DbExperience;
  // -- post_comment_reply_notif stuff
  post_comment_reply_commenter_full_name: string;
  post_comment_reply_post_id: string;
  // -- mention notif stuff
  mentioned_user_comment_author_full_name: string;
  mentioned_user_post_author_full_name: string;
  mentioned_in_comment_post_id: string;
  mentioned_in_post_post_id: string;
};

const formatNotificationFromDb = (
  dbNotification: DbNotification
): Notification => {
  const generic: GenericNotif = {
    id: dbNotification.id,
    actionRequired: dbNotification.action_required,
    viewedAt: dbNotification.viewed_at,
    createdAt: dbNotification.created_at,
  };
  switch (dbNotification.notification_type) {
    case "post-comment":
      return {
        ...generic,
        notificationType: "post-comment",
        commenter: {
          fullName:
            dbNotification.post_comment_notification_commenter_full_name,
        },
        postId: dbNotification.post_comment_notification_post_id,
      };
    case "post-comment-reply":
      return {
        ...generic,
        notificationType: "post-comment-reply",
        commenter: {
          fullName: dbNotification.post_comment_reply_commenter_full_name,
        },
        postId: dbNotification.post_comment_reply_post_id,
      };
    case "post-like":
      return {
        ...generic,
        notificationType: "post-like",
        likers: dbNotification.post_like_notification_likers.map((liker) => ({
          fullName: liker.liker_full_name,
          created_at: liker.created_at,
        })),
        postId: dbNotification.post_like_notification_post_id,
      };
    case "new-follower":
      return {
        ...generic,
        notificationType: "new-follower",
        follower: {
          fullName: dbNotification.new_follower_notification_follower_full_name,
          handle: dbNotification.new_follower_notification_follower_handle,
        },
      };
    case "profile-views":
      return {
        ...generic,
        notificationType: "profile-views",
        views: dbNotification.profile_view_notification_profile_views.map(
          (viewer) => ({
            viewer: {
              id: viewer.viewer_user_id,
              fullName: viewer.viewer_full_name,
              handle: viewer.viewer_handle,
              avatarUrl: viewer.viewer_photo_url,
              headline: viewer.viewer_headline,
              shape: viewer.viewer_shape as FrameShape,
            },
            createdAt: viewer.created_at,
          })
        ),
      };
    case "mentioned-user":
      return {
        ...generic,
        notificationType: "mentioned-user",
        mentioner: {
          fullName:
            dbNotification.mentioned_user_comment_author_full_name ||
            dbNotification.mentioned_user_post_author_full_name,
        },
        type: dbNotification.mentioned_in_post_post_id ? "post" : "comment",
        postId: dbNotification.mentioned_in_post_post_id
          ? dbNotification.mentioned_in_post_post_id
          : dbNotification.mentioned_in_comment_post_id,
      };
    case "id-verification-fail":
      return {
        ...generic,
        notificationType: "id-verification-fail",
      };
  }
};

export const getNotifications = async (): Promise<Notification[]> => {
  const { data, error } = await supabase.rpc("find_notifications");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.map(formatNotificationFromDb);
};

export const findNotification = async (
  notificationId: string
): Promise<Notification | undefined> => {
  const { data, error } = await supabase.rpc("find_notification", {
    notification_id: notificationId,
  });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return formatNotificationFromDb(data[0]);
};

export const viewNotifications = async (notificationIds: string[]) => {
  // update the store
  store.dispatch(markAsViewed(notificationIds));
  // persist the change
  await supabase
    .from("in_app_notification")
    .update({ viewed_at: new Date().toISOString() })
    .in("id", notificationIds);
};

export const useNotificationsRealtime = (
  userId?: string
): { isLoaded: boolean } => {
  const [isLoaded, setIsLoaded] = useState(false);

  const dispatch = useDispatch();

  const notificationsChannelName = "db-notifications";
  const isChannelHealthy = useRealtimeChannelHealth(
    notificationsChannelName,
    !!userId
  );

  useEffect(() => {
    // only sub if user is logged in
    if (!userId || (isLoaded && isChannelHealthy)) return;

    const channel = supabase.channel(notificationsChannelName);

    channel.on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "in_app_notification",
        filter: `user_id=eq.${userId}`,
      },
      async (payload) => {
        const notification = await findNotification(payload.new.id);
        if (notification) dispatch(addNotification(notification));
      }
    );

    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "in_app_notification",
        filter: `user_id=eq.${userId}`,
      },
      async (payload) => {
        // if action required was true and is now false
        // we update that in the store to make sure the counter reflects it
        if (payload.old.action_required && !payload.new.action_required)
          dispatch(markAsActionTaken([payload.new.id]));
      }
    );

    channel.on(
      "postgres_changes",
      {
        event: "DELETE",
        schema: "public",
        table: "in_app_notification",
        filter: `user_id=eq.${userId}`,
      },
      async (payload) => {
        dispatch(removeNotification(payload.old.id));
      }
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        const notifications = await getNotifications();
        dispatch(addNotifications(notifications));
        setIsLoaded(true);
      }
    });
  }, [dispatch, isLoaded, userId, isChannelHealthy]);

  return { isLoaded };
};
