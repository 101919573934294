import { isEqual } from "lodash";

import { useCallback, useEffect, useMemo, useState } from "react";

import { useRouter } from "next/router";

import { Filter, FilterValue, JobSearch } from "apis/jobs";

import { addToLocalStorage, getFromLocalStorage } from "./localStorage";
import { formatQueryParams, getJobsUrl, parseQueryParams } from "./urls";

const defaultSearchFilters = {
  tailored: "forYou",
  datePosted: "anytime",
} as const;

const groupToJobSearch: Record<string, JobSearch["search"]> = {
  "All jobs": { filters: { tailored: "all", datePosted: "anytime" } },
  Remote: { filters: { ...defaultSearchFilters, remote: "remote" } },
  Government: {
    filters: { ...defaultSearchFilters },
    value: "Government",
  },
  Finance: { filters: { ...defaultSearchFilters }, value: "Finance" },
  Engineering: { filters: { ...defaultSearchFilters }, value: "Engineering" },
  Startups: { filters: { ...defaultSearchFilters }, value: "Startups" },
  Health: { filters: { ...defaultSearchFilters }, value: "Health" },
  Internships: { filters: { ...defaultSearchFilters }, value: "Intern" },
  Education: { filters: { ...defaultSearchFilters }, value: "Education" },
};

export const useJobSearch = () => {
  const router = useRouter();
  const queryString = router.asPath.replace(router.pathname, "");
  const jobSearch = useMemo(
    () => parseQueryParams(decodeURI(queryString)) as JobSearch,
    [queryString]
  );
  const group = useMemo(() => {
    const entry = Object.entries(groupToJobSearch).find(([_, search]) =>
      isEqual(jobSearch.search, search)
    );
    return entry?.[0];
  }, [jobSearch]);

  const replaceJobSearchQuery = useCallback(
    (jobSearch: JobSearch) => {
      router.replace(
        `${getJobsUrl()}?${encodeURI(formatQueryParams(jobSearch))}`,
        undefined,
        {
          shallow: true,
        }
      );
    },
    [router]
  );

  const goToGroup = useCallback(
    (group: string) => {
      if (groupToJobSearch[group])
        replaceJobSearchQuery({ search: groupToJobSearch[group] });
    },
    [replaceJobSearchQuery]
  );

  const clearSearchFilters = useCallback(() => {
    const newJobSearch: JobSearch = {
      search: { filters: defaultSearchFilters },
      jobId: jobSearch.jobId,
    };
    replaceJobSearchQuery(newJobSearch); // Replace the query with the new empty JobSearch
  }, [replaceJobSearchQuery, jobSearch]);

  const upsertJobSearchFilter = useCallback(
    <T extends Filter>(filterName: T, filterValue: FilterValue<T>) => {
      const newJobSearch = { ...jobSearch };
      newJobSearch.search = newJobSearch.search || { filters: {} };
      newJobSearch.search.filters = newJobSearch.search.filters || {};
      // set filter value to given value
      newJobSearch.search.filters[filterName] = filterValue;
      // default filters
      newJobSearch.search.filters.tailored =
        newJobSearch.search.filters.tailored || "forYou";
      newJobSearch.search.filters.datePosted =
        newJobSearch.search.filters.datePosted || "anytime";
      // delete job id
      delete newJobSearch.jobId;
      replaceJobSearchQuery(newJobSearch);
    },
    [replaceJobSearchQuery, jobSearch]
  );

  const removeJobSearchFilter = useCallback(
    (filterName: Filter) => {
      const newJobSearch = { ...jobSearch };
      // delete give filter
      if (newJobSearch.search?.filters?.[filterName])
        delete newJobSearch.search.filters[filterName];

      replaceJobSearchQuery(newJobSearch);
    },
    [replaceJobSearchQuery, jobSearch]
  );

  const upsertJobSearchValue = useCallback(
    (searchValue: string) => {
      const newJobSearch = { ...jobSearch };
      newJobSearch.search = newJobSearch.search || { filters: {} };
      // set value to the search value
      newJobSearch.search.value = searchValue;
      newJobSearch.search.filters = newJobSearch.search.filters || {};
      // default filters
      newJobSearch.search.filters.tailored =
        newJobSearch.search.filters.tailored || "forYou";
      newJobSearch.search.filters.datePosted =
        newJobSearch.search.filters.datePosted || "anytime";
      // delete job id
      delete newJobSearch.jobId;
      replaceJobSearchQuery(newJobSearch);
    },
    [replaceJobSearchQuery, jobSearch]
  );

  const removeJobSearchValue = useCallback(() => {
    const newJobSearch = { ...jobSearch };
    delete newJobSearch.search?.value;
    replaceJobSearchQuery(newJobSearch);
  }, [replaceJobSearchQuery, jobSearch]);

  const upsertJobIdValue = useCallback(
    (jobId: string) => {
      const newJobSearch = { ...jobSearch };
      // add job id
      newJobSearch.jobId = jobId;
      // default filters
      newJobSearch.search = newJobSearch.search || { filters: {} };
      newJobSearch.search.filters = newJobSearch.search.filters || {};
      newJobSearch.search.filters.tailored =
        newJobSearch.search.filters.tailored || "forYou";
      newJobSearch.search.filters.datePosted =
        newJobSearch.search.filters.datePosted || "anytime";
      replaceJobSearchQuery(newJobSearch);
    },
    [replaceJobSearchQuery, jobSearch]
  );

  return {
    jobSearch,
    group,
    goToGroup,
    upsertJobSearchFilter,
    upsertJobSearchValue,
    upsertJobIdValue,
    removeJobSearchFilter,
    removeJobSearchValue,
    clearSearchFilters,
  };
};

export const useRecentJobSearches = () => {
  const key = useMemo(() => "recent-job-searches", []);
  const separator = useMemo(() => " ", []);
  const maxJobs = useMemo(() => 3, []);

  const [recentSearches, setRecentSearches] = useState<JobSearch[]>([]);

  const addToRecentSearches = useCallback(
    (search: JobSearch) => {
      const currents = getFromLocalStorage(key)?.split(separator) || [];
      const queryString = encodeURI(formatQueryParams(search));
      // if the search is already saved, do nothing
      if (currents.includes(queryString)) return;
      // add at the top
      currents.unshift(queryString);
      addToLocalStorage(key, currents.slice(0, maxJobs).join(separator));
    },
    [key, maxJobs, separator]
  );

  const getRecentSearches = useCallback(() => {
    const currents = getFromLocalStorage(key)?.split(separator) || [];

    if (currents.length === 1 && currents[0] === "") {
      return [];
    }

    return currents.map(
      (string) => parseQueryParams(decodeURI(string)) as JobSearch
    );
  }, [key, separator]);

  const clearRecentSearches = useCallback(() => {
    addToLocalStorage(key, "");
    setRecentSearches([]);
  }, [key]);

  useEffect(() => {
    setRecentSearches(getRecentSearches());
  }, [getRecentSearches]);

  return {
    addToRecentSearches,
    getRecentSearches,
    recentSearches,
    clearRecentSearches,
  };
};
