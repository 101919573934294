import { SerializedStyles, css } from "@emotion/react";

import { down } from "utils/mediaQueryStrings";

import { white } from "constants/colors";

const getStyle = (width?: number) =>
  css({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 16,
    background: white,
    boxShadow: "0px 2px 24px rgba(191, 134, 119, 0.05)",
    borderRadius: 8,
    width: width ?? "fit-content",
    [down("mobile")]: {
      width: "calc(100vw - 32px)",
      borderRadius: 0,
    },
  });

type Props = {
  children?: React.ReactNode;
  width?: number;
  customCss?: SerializedStyles;
};

export const Card = ({ children, width, customCss }: Props) => (
  <div css={[getStyle(width), customCss]}>{children}</div>
);
