export const NUM_OF_FREE_RECRUIT_RESPONSES = 50;
export const voices = [
  {
    id: "11labs-Anna",
    name: "Anna",
    recordingUrl: "/images/Anna.wav",
  },
  {
    id: "openai-Onyx",
    name: "Daniel",
    recordingUrl: "/images/Onyx.wav",
  },
  {
    id: "openai-Shimmer",
    name: "Maria",
    recordingUrl: "/images/Shimmer.wav",
  },
  {
    id: "11labs-Anthony",
    name: "Anthony",
    recordingUrl: "/images/Anthony.wav",
  },
  {
    id: "11labs-Kate",
    name: "Kate",
    recordingUrl: "/images/Kate.wav",
  },
  {
    id: "11labs-Amy",
    name: "Amy",
    recordingUrl: "/images/Amy.wav",
  },
];
export const defaultVoice = "11labs-Anna";
export const languages = [
  {
    id: "en-US",
    label: "English (United States)",
  },
  {
    id: "de-DE",
    label: "German (Germany)",
  },
  {
    id: "es-419",
    label: "Spanish (Latin America)",
  },
  {
    id: "hi-IN",
    label: "Hindi (India)",
  },
  {
    id: "ja-JP",
    label: "Japanese (Japan)",
  },
  {
    id: "pt-BR",
    label: "Portuguese (Brazil)",
  },
  {
    id: "fr-FR",
    label: "French (France)",
  },
];
export const defaultLanguage = "en-US";
