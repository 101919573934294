import { SearchIcon } from "Icons/Search";

import { useEffect, useState } from "react";

import { css } from "@emotion/react";

import { useRouter } from "next/router";

import { ButtonWrapper } from "components/shared/library/ButtonWrapper";
import { DropdownDeprecated } from "components/shared/library/DropdownDeprecated";
import { Input } from "components/shared/library/Input";
import { OptionsMenu } from "components/shared/library/OptionsMenu";
import { SpinningIcon } from "components/shared/library/SpinningIcon";
import { Typography } from "components/shared/library/Typography";

import { OrgSearchResult, UserSearchResult, searchAll } from "apis/search";

import { useJobSearch } from "utils/jobsSearch";
import { useCloseOnClickOutside } from "utils/libraryHooks";
import { addToLocalStorage, getFromLocalStorage } from "utils/localStorage";
import { isViewMobile } from "utils/mediaQueryStrings";
import { t } from "utils/translation";
import {
  getJobsUrl,
  getOrgProfileUrl,
  getProfileUrl,
  getSearchUrl,
} from "utils/urls";

import { purple, uiGray } from "constants/colors";
import { recentlySearchedLimit } from "constants/recentlySearched";

import { RecentlySearchedValue } from "./RecentlySearchedItem";
import { SearchResultItem } from "./SearchResultItem";

const getStyle = (isFullWidth: boolean) => {
  return {
    wrapper: css({
      width: isFullWidth ? "-webkit-fill-available" : 313,
    }),
    selected: css({
      display: "flex",
      borderRadius: 40,
      "&:focus-within": {
        outline: `1px solid ${purple[600]}`,
        boxShadow: `0px 0px 0px 4px ${purple.focusShadow}`,
      },
    }),
    dropDownWrapper: css({
      flexGrow: 0,
      flexShrink: 1,
      flexBasis: "auto",
      minWidth: 89,
      maxWidth: 313,
      width: "max-content",
    }),
    dropDownText: css({
      wordBreak: "normal",
    }),
    dropDownBtn: css({
      minWidth: 89,
      padding: "9px 14px",
      width: "max-content",
      borderRadius: "40px 0px 0px 40px",
      "&:focus-within": {
        outline: "none",
        boxShadow: "none",
      },
    }),
    inputWrapper: css({
      height: 14,
      borderRadius: "0px 40px 40px 0px",
      borderLeft: "none",
      "&:focus-within": {
        outline: "none",
        boxShadow: "none",
      },
    }),
    inputOuterWrapper: css({
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto",
    }),
    optionsMenu: css({
      position: "initial",
      bottom: "none",
      right: "none",
      transform: "translateY(12px)",
      maxWidth: 313,
    }),
    optionMenuItem: css({
      display: "initial",
    }),
    optionsMenuFooter: css({
      padding: "14px 8px 14px 8px",
      justifyContent: "center",
      display: "flex",
      borderTop: `1px solid rgba(141, 141, 141, 0.05)`,
    }),
    footerContent: css({
      alignItems: "center",
      display: "flex",
      gap: 8,
    }),
    header: css({
      padding: "4px 12px 4px 12px",
      marginLeft: 8,
      marginTop: 10,
    }),
  };
};

export type SearchResultDivider = {
  name: string;
  label: string;
  type: "divider";
};

const isRedirectType = (value: string): boolean => {
  // add more values here as we expand the redirect Types.
  return value === "Jobs";
};

export type OptionType = "All" | "People" | "Orgs";
export type RedirectType = "Jobs";
const options: {
  name: OptionType | RedirectType;
  label: OptionType | RedirectType;
}[] = [
  {
    name: "All",
    label: "All",
  },
  {
    name: "People",
    label: "People",
  },
  {
    name: "Orgs",
    label: "Orgs",
  },
  {
    name: "Jobs",
    label: "Jobs",
  },
];

type Props = {
  isFullWidth?: boolean;
  showSearchIcon?: boolean;
  searchFilter?: OptionType | RedirectType;
};

export const SearchBar = ({
  isFullWidth = false,
  showSearchIcon = false,
  searchFilter = "All",
}: Props) => {
  const [value, setValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [recentSearches, setRecentSearches] = useState<RecentlySearchedValue[]>(
    []
  );
  const [optionsMenuItems, setOptionsMenuItems] = useState<
    (
      | UserSearchResult
      | OrgSearchResult
      | SearchResultDivider
      | RecentlySearchedValue
    )[]
  >([]);
  const [selectedFilter, setSelectedFilter] = useState<
    OptionType | RedirectType
  >(searchFilter);

  const router = useRouter();
  const { jobSearch, upsertJobSearchValue, removeJobSearchValue } =
    useJobSearch();

  const style = getStyle(isFullWidth);

  useEffect(() => {
    const recentlySearched = localStorage.getItem("recently-searched");
    if (recentlySearched !== null) {
      setRecentSearches(
        JSON.parse(recentlySearched).map((value: string) => ({
          name: value,
          label: value,
          type: "recently-searched",
        }))
      );
    }
  }, [value]);

  useEffect(() => {
    if (value.length <= 2) {
      setShowHeader(true);
      setOptionsMenuItems(recentSearches);
    }
  }, [value, recentSearches]);

  useEffect(() => {
    if (isRedirectType(selectedFilter)) return; // do not display search result on search bar if Jobs is selected
    if (value.trim().length < 3) return;
    setShowOptions(true);
    setIsLoading(true);

    const search = async () => {
      const searchResults = await searchAll(value.trim(), 3, 1, 1);

      setOptionsMenuItems([
        ...((selectedFilter === "All" || selectedFilter === "People") &&
        searchResults.users.length >= 1
          ? [
              {
                name: "People",
                label: "People",
                type: "divider" as const,
              },
              ...searchResults.users,
            ]
          : []),
        ...((selectedFilter === "All" || selectedFilter === "Orgs") &&
        searchResults.orgs.length >= 1
          ? [
              {
                name: "Organizations",
                label: "Organizations",
                type: "divider" as const,
              },
              ...searchResults.orgs,
            ]
          : []),
      ]);
      setShowHeader(false);

      setIsLoading(false);
    };

    search();
  }, [value, selectedFilter]);

  // Jobs-specific logic:
  // update value if selected filter is Jobs and search query changes
  useEffect(() => {
    if (selectedFilter !== "Jobs") return;

    setValue(jobSearch.search?.value || "");
  }, [jobSearch.search?.value, selectedFilter]);

  const addToRecentlySearched = (value: string) => {
    if (value.trim() === "") return;
    const recentlySearched = getFromLocalStorage("recently-searched");
    if (recentlySearched !== null) {
      const existingArray = JSON.parse(recentlySearched);
      if (!existingArray.includes(value)) {
        if (existingArray.length === recentlySearchedLimit) {
          existingArray.shift();
        }
        existingArray.push(value.trim());
        addToLocalStorage("recently-searched", existingArray);
      }
    } else {
      addToLocalStorage("recently-searched", [value]);
    }
  };

  useCloseOnClickOutside(showOptions, setShowOptions, "search-bar");

  return (
    <div css={style.wrapper} id={"search-bar"}>
      <div css={style.selected}>
        <DropdownDeprecated
          IconLeft={showSearchIcon && !isViewMobile() ? SearchIcon : undefined}
          label="Search filter"
          displayLabel={false}
          isOptional
          options={options}
          onChange={(value) => {
            if (value === "Jobs" && router.asPath !== getJobsUrl())
              router.push(getJobsUrl());
            if (
              value === "All" ||
              value === "People" ||
              value === "Orgs" ||
              value === "Jobs"
            )
              setSelectedFilter(value);
          }}
          defaultValue={selectedFilter}
          customCss={{
            wrapper: style.dropDownWrapper,
            button: style.dropDownBtn,
          }}
        />

        <Input
          label={"Search"}
          onChange={setValue}
          onFocus={() => {
            setShowOptions(true);
          }}
          onEnter={() => {
            if (selectedFilter === "Jobs" && value.trim().length === 0) {
              removeJobSearchValue();
              return;
            }

            if (selectedFilter === "Jobs") {
              upsertJobSearchValue(value);
              return;
            }

            addToRecentlySearched(value);
            router.push(getSearchUrl(selectedFilter as OptionType, value));
          }}
          displayLabel={false}
          PreTabComponents={[]}
          placeholder={"Search Ribbon"}
          customCss={{
            wrapper: style.inputWrapper,
            outerWrapper: style.inputOuterWrapper,
          }}
          value={value}
        />
      </div>
      <OptionsMenu
        selectedValue={"1"}
        customEmptyStateLabel={
          showHeader
            ? ""
            : "No results found. Try different keywords or check filters."
        }
        customCss={{ wrapper: style.optionsMenu, option: style.optionMenuItem }}
        isVisible={
          (value.length >= 3 || isLoading || recentSearches.length >= 0) &&
          showOptions &&
          selectedFilter !== "Jobs"
        }
        onChange={(option) => {
          if (option.type === "user") {
            router.push(getProfileUrl(option.user.handle));
          } else if (option.type === "org") {
            router.push(getOrgProfileUrl(option.org.id));
          } else if (option.type === "recently-searched") {
            router.push(
              getSearchUrl(selectedFilter as OptionType, option.label)
            );
          }
        }}
        options={optionsMenuItems}
        OptionComponent={(
          item:
            | UserSearchResult
            | OrgSearchResult
            | SearchResultDivider
            | RecentlySearchedValue
        ) => <SearchResultItem item={item} searchValue={value} />}
        HeaderComponent={
          showHeader ? (
            <div css={style.header}>
              <Typography weight="medium" size="XS" color={uiGray[50]}>
                Recently searched
              </Typography>
            </div>
          ) : undefined
        }
        FooterComponent={
          <div css={style.optionsMenuFooter}>
            <ButtonWrapper
              onClick={() => {
                addToRecentlySearched(value);
                router.push(getSearchUrl(selectedFilter as OptionType, value));
              }}
            >
              <div css={style.footerContent}>
                <Typography color={purple[600]} weight="bold" size="XS">
                  {t("See all results")}
                </Typography>
                {isLoading && <SpinningIcon width={16} height={16} />}
              </div>
            </ButtonWrapper>
          </div>
        }
      />
    </div>
  );
};
