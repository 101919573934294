import React, { useContext, useEffect, useState } from "react";

import { useRouter } from "next/router";

import { useIsMobileViewPort } from "utils/libraryHooks";
import { getJobsUrl } from "utils/urls";

const PageLayoutContext = React.createContext<Context>({
  leftPanelStatus: "locked",
  setLeftPanelStatus: () => undefined,
  leftPanelDisplayButtonId: "",
  isLockable: true,
});

type LeftPanelStatus = "locked" | "collapsed" | "preview" | "SSR";

type Context = {
  leftPanelStatus: LeftPanelStatus;
  setLeftPanelStatus: (value: LeftPanelStatus) => void;
  leftPanelDisplayButtonId: string;
  isLockable: boolean;
};

type Props = {
  children: React.ReactNode;
};

export const PageLayoutProvider = ({ children }: Props) => {
  const isMobileViewport = useIsMobileViewPort();
  const router = useRouter();

  const [leftPanelStatus, setLeftPanelStatus] =
    useState<LeftPanelStatus>("SSR");

  useEffect(() => {
    if (isMobileViewport !== "SSR")
      setLeftPanelStatus(isMobileViewport ? "collapsed" : "locked");
  }, [isMobileViewport]);

  useEffect(() => {
    if (router.pathname === getJobsUrl()) setLeftPanelStatus("collapsed");
  }, [isMobileViewport, router.pathname]);

  return (
    <PageLayoutContext.Provider
      value={{
        leftPanelStatus,
        setLeftPanelStatus,
        leftPanelDisplayButtonId: "left-panel-display-button-id",
        isLockable: !isMobileViewport,
      }}
    >
      {children}
    </PageLayoutContext.Provider>
  );
};

export default PageLayoutContext;

export const usePageLayoutContext = () => useContext(PageLayoutContext);
