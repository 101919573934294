import { ChevronDownIcon } from "Icons/ChevronDown";
import { ChevronUpIcon } from "Icons/ChevronUp";
import { IconType } from "Icons/types";
import { Dropdown as AntDDropdown } from "antd";

import { useMemo, useState } from "react";

import { Global, SerializedStyles, css } from "@emotion/react";

import { purple, uiGray, white } from "constants/colors";

import { FieldLabel } from "./FieldLabel";
import { OptionType } from "./OptionsMenu";
import { Typography } from "./Typography";

const style = {
  wrapper: css({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
  }),
  label: css({ marginBottom: 8 }),
  input: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 14px",
    background: white,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: uiGray[10],
    borderRadius: 12,
    gap: 8,
    "&:focus": {
      outline: `1px solid ${purple[600]}`,
      boxShadow: `0px 0px 0px 4px ${purple.focusShadow}`,
    },
    "&::placeholder": {
      color: uiGray[60],
    },
    "&:disabled": {
      color: uiGray[50],
      background: "rgba(141, 141, 141, 0.05)",
      borderColor: "#ECECEC",
    },
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "20px",
    color: uiGray[100],
    flex: 1,
  }),
  // antd has high specificity so in order to override their styles we use the Global API
  // the risk with the global API is that the style is not contained to this component which
  // would make debugging another componen using ant-dropdown-menu-item very hard to debug
  // so we add a custom classname ribbon-dropdown-wrapper to make sure this global style only applies to that component
  antd: css({
    "& .ribbon-dropdown-wrapper .ant-dropdown-menu": {
      borderRadius: 12,
      boxShadow: "0px 0px 0px 1px #F5F6FA, 0px 0px 8px rgba(72, 40, 228, 0.15)",
      transform: "translateY(6px)",
    },
    "& .ribbon-dropdown-wrapper .ant-dropdown-menu-item": {
      padding: "8px 12px !important",
      borderRadius: "8px !important",
      "&:hover": { background: `${purple[50]} !important` },
      color: `${uiGray[80]} !important`,
      fontWeight: "500 !important",
      fontSize: "14px !important",
      lineHeight: "20px !important",
    },
  }),
};

type Props<T> = {
  onChange: (value?: string) => void;
  options: T[];
  label: string;
  defaultValue?: string;
  displayLabel?: boolean;
  isOptional?: boolean;
  IconLeft?: IconType;
  placeholder?: string;
  customCss?: {
    wrapper?: SerializedStyles;
    button?: SerializedStyles;
    text?: SerializedStyles;
  };
};

export const Dropdown = <T extends OptionType>({
  onChange,
  options,
  label,
  displayLabel = true,
  isOptional,
  defaultValue,
  placeholder,
  IconLeft,
  customCss,
}: Props<T>) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    defaultValue
  );
  const selectedLabel = useMemo(
    () => options.find((option) => option.name === selectedValue)?.label,
    [options, selectedValue]
  );

  return (
    <div css={customCss?.wrapper}>
      {displayLabel && <FieldLabel label={label} isOptional={isOptional} />}
      <Global styles={style.antd} />
      <div css={style.wrapper}>
        <AntDDropdown
          overlayClassName="ribbon-dropdown-wrapper"
          menu={{
            items: options.map(({ label, name }) => ({ key: name, label })),
            onClick: ({ key }) => {
              onChange(key);
              setSelectedValue(key);
              setShowOptions(false);
            },
          }}
          trigger={["click"]}
        >
          <button
            role="combobox"
            aria-label={label}
            css={[style.input, customCss?.button]}
            onClick={(e) => {
              e.preventDefault();
              setShowOptions((state) => !state);
            }}
          >
            {IconLeft && (
              <IconLeft stroke={purple[600]} width={16} height={16} />
            )}
            {placeholder && !selectedLabel && (
              <Typography color={uiGray[60]} customCss={customCss?.text}>
                {placeholder}
              </Typography>
            )}
            <Typography customCss={customCss?.text}>{selectedLabel}</Typography>
            {showOptions ? (
              <ChevronUpIcon width={16} height={16} stroke={uiGray[90]} />
            ) : (
              <ChevronDownIcon width={16} height={16} stroke={uiGray[90]} />
            )}
          </button>
        </AntDDropdown>
      </div>
    </div>
  );
};
