import { Dispatch, SetStateAction } from "react";

import { css } from "@emotion/react";

import { usePageLayoutContext } from "contexts/PageLayoutContext";

import { down } from "utils/mediaQueryStrings";

import { BrandHeader } from "./BrandHeader";

const getStyles = (collapsed: boolean) => {
  return {
    panelWrapper: css({
      padding: collapsed ? "16px 8px" : "16px 16px 32px 24px",
      display: "flex",
      flexDirection: "column",
      alignItems: collapsed ? "center" : "initial",
      gap: 56,
      height: "100%",
      overflowY: "auto",
      scrollbarWidth: "thin",
      WebkitOverflowScrolling: "touch",
      "&::-webkit-scrollbar": {
        width: "0px", // Adjust the width as needed
        display: "none", // Hide the scrollbar
      },
      "&:hover::-webkit-scrollbar": {
        display: "none", // Hide the scrollbar on hover
      },
    }),
    header: css({
      display: "flex",
      width: collapsed ? "initial" : "100%",
      justifyContent: "space-between",
      alignItems: "center",
      [down("mobile")]: {
        display: "none",
      },
    }),
  };
};

export const SidePanel = ({
  setIsOrgDrawerOpen,
  children,
}: {
  children: React.ReactNode;
  setIsOrgDrawerOpen?: Dispatch<SetStateAction<boolean>>;
}) => {
  const { leftPanelStatus, setLeftPanelStatus } = usePageLayoutContext();
  const style = getStyles(leftPanelStatus === "collapsed");

  return (
    <div
      css={style.panelWrapper}
      onClick={(event) => {
        if (
          event.target === event.currentTarget && // check if the white space is clicked and not any of the children of the side bar.
          leftPanelStatus === "collapsed"
        )
          setLeftPanelStatus("locked");
      }}
    >
      <div css={style.header}>
        <BrandHeader
          showLogoOnly={leftPanelStatus === "collapsed"}
          onClick={() => {
            if (setIsOrgDrawerOpen) setIsOrgDrawerOpen(false);
            setTimeout(() => {
              setLeftPanelStatus("collapsed");
            }, 100);
          }}
        />
      </div>
      {children}
    </div>
  );
};
