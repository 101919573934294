import { Role, UiRole } from "apis/orgRoles";
import { InviteType } from "apis/orgSignUps";

export const mapUiRoleToOrgSignupType: Record<UiRole, InviteType | undefined> =
  {
    "Super Admin": "email-admin",
    Issuer: "email-issuer",
    Employee: "email",
    Pending: undefined,
  };

export const mapOrgSignupTypeToRole: Record<InviteType, Role[]> = {
  "email-admin": ["basic", "role-admin", "issuer", "billing"],
  "email-issuer": ["basic", "issuer"],
  email: ["basic"],
  domain: ["basic"],
  "one-time-invitation": ["basic"],
  "multi-user-invitation": ["basic"],
};

export const helperTextByUiRole: Record<UiRole, string> = {
  "Super Admin": "In addition to below, has access to billing",
  Issuer: "Can issue and receive awards",
  Employee: "Receive awards",
  Pending: "",
};
