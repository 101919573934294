import { css } from "@emotion/react";

import { OrgAvatar } from "components/shared/library/OrgAvatar";
import { OrgVerified } from "components/shared/library/OrgVerified";

import { OrgSearchResult } from "apis/search";

import { placeholderOrgLogo } from "constants/images";

const style = {
  wrapper: css({
    display: "flex",
    alignItems: "center",
    gap: 16,
  }),
  avatar: css({
    float: "right",
  }),
};

type Props = {
  item: OrgSearchResult;
};

export const OrgSearchResultItem = ({ item }: Props) => {
  return (
    <div css={style.wrapper} data-testid="org-search-result-item">
      <div css={style.avatar}>
        {item.type === "org" && (
          <OrgAvatar
            fileName={item.org.photoUrl || placeholderOrgLogo}
            handle={item.org.id}
            size={32}
          />
        )}
      </div>
      <OrgVerified org={item.org} size="XS" />
    </div>
  );
};
