import { isEqual } from "lodash";

import { MutableRefObject, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { logger } from "interfaces/logger";
import { addFollowedUserIds, removeFollowedUserIds } from "store/followSlice";
import store from "store/store";

import { supabase } from "apis/supabaseClient";

import { fetchAuthWrapper } from "./backend";

export const followUser = async (
  followedUserId: string
): Promise<{ error?: string }> => {
  store.dispatch(addFollowedUserIds([followedUserId]));

  const res = await fetchAuthWrapper.post("/be-api/follow-user", {
    followedUserId,
  });

  if (res.status !== 200) {
    logger("Error following user", "error");

    store.dispatch(removeFollowedUserIds([followedUserId]));
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  const error = body.error;

  return { error };
};

export const unfollowUser = async (
  followedUserId: string
): Promise<{ error?: string }> => {
  store.dispatch(removeFollowedUserIds([followedUserId]));

  const res = await fetchAuthWrapper.post("/be-api/unfollow-user", {
    followedUserId,
  });

  if (res.status !== 200) {
    logger("Error unfollowing user", "error");
    store.dispatch(addFollowedUserIds([followedUserId]));

    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  const error = body.error;

  return { error };
};

export const getIsFollowingUser = async (
  followingUserId: string,
  followedUserIds: string[]
): Promise<boolean[]> => {
  const { data, error } = await supabase
    .from("follow_user")
    .select("*")
    .match({ following_user_id: followingUserId })
    .in("followed_user_id", followedUserIds);

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return followedUserIds.map((followedUserId) =>
    data?.some((followUser) => followUser.followed_user_id === followedUserId)
  );
};

export const useIsFollowing = (
  followingUserId: string | undefined,
  followedUserIds: string[] | undefined
) => {
  const dispatch = useDispatch();
  const prevFollowedUserIds: MutableRefObject<string[] | null> = useRef(null);

  useEffect(() => {
    if (
      !followingUserId ||
      !followedUserIds ||
      isEqual(prevFollowedUserIds.current, followedUserIds)
    ) {
      return;
    }

    const get = async () => {
      const isFollowingUserResults = await getIsFollowingUser(
        followingUserId,
        followedUserIds
      );

      const toRemove = followedUserIds.filter(
        (followedUserId, index) => !isFollowingUserResults[index]
      );

      if (toRemove.length > 0) {
        dispatch(removeFollowedUserIds(toRemove));
      }

      const toAdd = followedUserIds.filter(
        (followedUserId, index) => isFollowingUserResults[index]
      );

      if (toAdd.length > 0) {
        dispatch(addFollowedUserIds(toAdd));
      }

      prevFollowedUserIds.current = followedUserIds;
    };
    get();
  }, [followingUserId, followedUserIds, dispatch, prevFollowedUserIds]);
};
