import { Role } from "apis/orgRoles";

import { useUserHasAnyRole } from "utils/roles";

import { Navigate } from "./Navigate";

type Props = {
  children: React.ReactNode;

  role: Role;
  redirectToPath?: string;
};

export const Authorized = ({ children, role, redirectToPath }: Props) => {
  const authorized = useUserHasAnyRole([role]);

  if (!authorized) {
    if (redirectToPath) return <Navigate to={redirectToPath} />;
    return null;
  }

  return <>{children}</>;
};
