import { useSelector } from "react-redux";

import { logger } from "interfaces/logger";
import { selectAccountProfile } from "store/accountProfileSlice";

import { useAuth } from "contexts/authContext";

import { Role, UiRole } from "apis/orgRoles";
import { useOrgRolesAndLimitsRealtime } from "apis/orgs";

import {
  mapOrgSignupTypeToRole,
  mapUiRoleToOrgSignupType,
} from "constants/roles";

export const useUserHasAnyRole = (authorizedRoles: Role[]): boolean => {
  const { user } = useAuth();
  const accountProfile = useSelector(selectAccountProfile);
  const { orgRolesAndLimits } = useOrgRolesAndLimitsRealtime(
    accountProfile?.id,
    accountProfile?.activeOrgId
  );
  if (!user) return false;
  if (!accountProfile || !accountProfile.activeOrgId) return false;
  const userRoles = orgRolesAndLimits[accountProfile.activeOrgId]?.roles;

  return authorizedRoles.some((r) => userRoles?.includes(r));
};

// there is a hierarchy in roles from the user POV:
// a Super Admin has all the roles
// a Issuer user has all roles except the role-admin and billing one
export const mapRolesToUiRoles = (roles: Role[]): UiRole => {
  if (roles.includes("role-admin")) return "Super Admin";
  if (roles.includes("issuer")) return "Issuer";
  if (!roles.includes("basic"))
    logger("Roles should always include at least the basic role", "error");

  return "Employee";
};

export const mapUiRoleToRoles = (uiRole: UiRole): Role[] => {
  const orgSignup = mapUiRoleToOrgSignupType[uiRole];
  if (orgSignup) return mapOrgSignupTypeToRole[orgSignup];

  return [];
};
