import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { logger } from "interfaces/logger";
import {
  addEarning,
  selectEarning,
  redeem as redeemAction,
} from "store/earningsSlice";
import store from "store/store";

import { supabase } from "apis/supabaseClient";

import { useRealtimeChannelHealth } from "utils/realtime";

import { fetchAuthWrapper } from "./backend";

export type TransactionStatus = "earned" | "redeemed" | "paid_out";
export type TransactionType = "views_incentive" | "redeemed" | "paid_out";

type StripeAccount = { brand?: string; lastFour?: number };

export type Earning = {
  since: string;
  viewsCount: number;
  transactions: Transaction[];
  allEarnings: number;
  experienceVerifCount: number;
  hasStripeAccount: boolean;
  redeemableEarnings: number;
};

export type Transaction = {
  createdAt: string;
  transactionStatus: TransactionStatus;
  amountInCents: number;
  transactionType: TransactionType;
  typeUnit: number;
};

type DbTransaction = {
  created_at: string;
  transaction_status: TransactionStatus;
  amount: number;
  transaction_type: TransactionType;
  type_unit: number;
};

const getEarnings = async (): Promise<Earning | null> => {
  const { data, error } = await supabase.rpc("get_earnings");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return {
    since: data[0].user_joined_at,
    viewsCount: data[0].views_count || 0,
    transactions:
      data[0].transactions?.map((transaction: DbTransaction) => ({
        createdAt: transaction.created_at,
        transactionStatus: transaction.transaction_status,
        amountInCents: transaction.amount,
        transactionType: transaction.transaction_type,
        typeUnit: transaction.type_unit,
      })) || [],
    allEarnings: data[0].all_earnings || 0,
    experienceVerifCount:
      data[0].redeem_earnings_params.experience_verif_count || 0,
    hasStripeAccount:
      data[0].redeem_earnings_params.has_stripe_account || false,
    redeemableEarnings: data[0].redeem_earnings_params.redeemable_earnings || 0,
  };
};

export const useEarning = (): Earning | undefined => {
  useEffect(() => {
    const fetch = async () => {
      const earning = await getEarnings();
      if (earning) store.dispatch(addEarning(earning));
    };

    fetch();
  }, []);

  const earnings = useSelector(selectEarning);
  return earnings;
};

export const getRedeemEarningsParams = async (
  userId: string
): Promise<{
  hasStripeAccount: boolean;
}> => {
  const { data, error } = await supabase.rpc("get_redeem_earnings_params", {
    query_user_id: userId,
  });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return {
    hasStripeAccount: data?.[0]?.has_stripe_account,
  };
};

export const redeem = async (): Promise<{ error?: string }> => {
  const res = await fetchAuthWrapper.post("/be-api/redeem", {});

  if (res.status !== 200) {
    logger("Error redeeming earnings", "error");
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  const error = body.error;
  if (!error) store.dispatch(redeemAction());
  return { error };
};

export const setupStripeAccount = async (): Promise<{
  error?: string;
  data?: { accountId: string; accountSetupUrl: string };
}> => {
  const res = await fetchAuthWrapper.post("/be-api/setup-stripe-account", {});

  if (res.status !== 200) {
    logger("Error setting stripe account", "error");
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  const error = body.error;

  return {
    error,
    data: {
      accountId: body.account_id,
      accountSetupUrl: body.account_setup_url,
    },
  };
};

export const findStripeAccount = async (
  userId: string
): Promise<StripeAccount | undefined> => {
  const { data, error } = await supabase
    .from("stripe_account")
    .select("brand,last_four")
    .eq("user_id", userId);

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  if (data[0]) return { brand: data[0]?.brand, lastFour: data[0].last_four };
  return undefined;
};

export const useStripeAccountRealtime = (
  userId: string
): StripeAccount | undefined => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [stripeAccount, setStripeAccount] = useState<StripeAccount>();

  const stripeAccountChannelName = "db-stripe-account";
  const isChannelHealthy = useRealtimeChannelHealth(
    stripeAccountChannelName,
    !!userId
  );

  useEffect(() => {
    if (!userId || (isLoaded && isChannelHealthy)) return;

    const channel = supabase.channel(stripeAccountChannelName);

    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "stripe_account",
        filter: `user_id=eq.${userId}`,
      },
      async (payload) => {
        setStripeAccount({
          brand: payload.new.brand,
          lastFour: payload.new.last_four,
        });
      }
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED" && !isLoaded) {
        setStripeAccount(await findStripeAccount(userId));
        setIsLoaded(true);
      }
    });
  }, [isLoaded, isChannelHealthy, userId]);

  return stripeAccount;
};

export const getViewIncentiveVariables = async () => {
  const { data, error } = await supabase
    .from("earning_variables_setting")
    .select("amount, type_unit")
    .eq("transaction_type", "views_incentive");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return {
    amountInCents: data[0]?.amount,
    typeUnit: data[0]?.type_unit,
  };
};
