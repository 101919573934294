import { useSelector } from "react-redux";

import { css } from "@emotion/react";

import { selectFollowedUserIds } from "store/followSlice";
import { State } from "store/store";

import { TrashIcon } from "Icons/Trash";

import { useAuth } from "contexts/authContext";

import { followUser, unfollowUser } from "apis/follow";
import { UserInfo } from "apis/user";

import { getUserFullName } from "utils/accountProfile";
import { isMobile } from "utils/device";

import { uiGray } from "constants/colors";

import { Avatar } from "./Avatar";
import { Button } from "./Button";
import { Typography } from "./Typography";
import { UserAvatar } from "./UserAvatar";

const style = {
  wrapper: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 16,
  }),
  user: css({
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    alignItems: "center",
  }),
  userText: css({
    display: "flex",
    flexDirection: "column",
  }),
  buttonStyle: css({
    width: 95,
  }),
  followDivs: css({
    display: "flex",
    gap: 8,
    alignItems: "center",
  }),
  ellipsis: css({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
  }),
};

type Props = {
  user: Partial<
    Pick<
      UserInfo,
      "id" | "handle" | "fullName" | "shape" | "avatarUrl" | "headline"
    >
  > & { addressId?: string };
  onDelete?: () => void;
};

export const UserListItem = ({ user, onDelete }: Props) => {
  const { user: authUser } = useAuth();

  const isFollowing = useSelector((state: State) =>
    user.id ? selectFollowedUserIds(state, user.id) : false
  );

  return (
    <div css={style.wrapper}>
      <div css={style.user}>
        {user.id && user.handle ? (
          <UserAvatar
            fileName={user.avatarUrl}
            frameShape={user.shape}
            handle={user.handle}
          />
        ) : (
          <Avatar
            fileName={user.avatarUrl}
            localUrl={user.avatarUrl}
            frameShape={user.shape}
          />
        )}

        <div css={style.userText}>
          <Typography size="S" weight="bold" color={uiGray[90]}>
            {getUserFullName(user)}
          </Typography>
          <Typography size="XS" color={uiGray[80]} customCss={style.ellipsis}>
            {(user.headline && user.headline) ||
              (user.handle && `@${user.handle}`) ||
              user.addressId}
          </Typography>
        </div>
      </div>
      <div css={style.followDivs}>
        {authUser?.id &&
          !isFollowing &&
          user.id &&
          authUser?.id !== user.id && (
            <Button
              customCss={{
                button: style.buttonStyle,
              }}
              color="black"
              size={isMobile() ? "extra-small" : "small"}
              onClick={() => {
                if (user.id) followUser(user.id);
              }}
            >
              Follow
            </Button>
          )}
        {authUser?.id && isFollowing && user.id && authUser?.id !== user.id && (
          <Button
            customCss={{
              button: style.buttonStyle,
            }}
            color="black"
            size={isMobile() ? "extra-small" : "small"}
            background="outlined"
            onClick={() => {
              if (user.id) unfollowUser(user.id);
            }}
          >
            Following
          </Button>
        )}
        {onDelete && (
          <Button
            IconLeft={TrashIcon}
            onClick={onDelete}
            background="ghost"
            color="black"
            ariaLabel="Delete"
          />
        )}
      </div>
    </div>
  );
};
