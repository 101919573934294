import { CheckColorIcon } from "Icons/CheckColor";

import { useSelector } from "react-redux";

import { SerializedStyles, css } from "@emotion/react";

import { useRouter } from "next/router";
import { selectAccountProfile } from "store/accountProfileSlice";

import { getUserFullName } from "utils/accountProfile";
import { getProfileUrl } from "utils/urls";

import {
  SHIMMER_KEYFRAME,
  SHIMMER_TRANSITION_DURATION,
  SHIMMER_TRANSITION_TIMING_FUNCTION,
} from "constants/animations";
import { uiGray, white } from "constants/colors";

import { Avatar } from "../library/Avatar";
import { ButtonWrapper } from "../library/ButtonWrapper";
import { Card } from "../library/Card";
import { Typography } from "../library/Typography";

const style = {
  container: css({
    width: "100%",
    padding: 12,
    display: "flex",
    justifyContent: "center",
    gap: 8,
    alignItems: "center",
    "&:hover": {
      background: uiGray[10],
      borderRadius: 8,
    },
  }),
  textContainer: css({
    display: "grid",
    gridTemplateRows: "auto auto",
    alignItems: "center",
    flex: 1,
  }),
  ellipsis: css({
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }),
  shimmer: css({
    animation: `${SHIMMER_KEYFRAME} ${SHIMMER_TRANSITION_DURATION} infinite ${SHIMMER_TRANSITION_TIMING_FUNCTION}`,
    background: `linear-gradient(to right, ${white} 4%, ${uiGray.pageBackground} 25%, ${white} 66%)`,
    backgroundSize: "1000px 100%",
  }),
  disabled: css({
    "&:disabled": {
      opacity: 1,
      pointerEvents: "none",
    },
  }),
};

type Props = {
  showAvatarOnly?: boolean;
  customCss?: SerializedStyles;
  disableClick?: boolean;
};

export const UserAccountDisplay = ({
  showAvatarOnly = false,
  disableClick = false,
  customCss,
}: Props) => {
  const accountProfile = useSelector(selectAccountProfile);
  const router = useRouter();

  if (!accountProfile) return null;

  return (
    <ButtonWrapper
      onClick={() => {
        router.push(getProfileUrl(accountProfile.handle));
      }}
      customCss={[
        style.container,
        disableClick ? style.disabled : undefined,
        customCss,
      ]}
      ariaLabel="Go to profile page"
      disabled={disableClick}
    >
      {accountProfile.handle === undefined ? (
        <Card width={515} customCss={style.shimmer}>
          <svg
            viewBox="0 0 200 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20" cy="7.5" r="10" fill="#F5F6FA" />
          </svg>
        </Card>
      ) : (
        <>
          <Avatar
            fileName={accountProfile.avatarUrl}
            size={showAvatarOnly ? 24 : 48}
            frameShape={accountProfile.shape}
            Icon={accountProfile.isVerified ? CheckColorIcon : undefined}
          />
          {!showAvatarOnly && (
            <div css={style.textContainer}>
              <Typography
                size="XS"
                color={uiGray[100]}
                weight="bold"
                tag="p"
                customCss={style.ellipsis}
              >
                {getUserFullName(accountProfile)}
              </Typography>
              {accountProfile.headline && (
                <Typography
                  size="XXS"
                  color={uiGray[60]}
                  tag="p"
                  customCss={style.ellipsis}
                >
                  {accountProfile.headline}
                </Typography>
              )}
            </div>
          )}
        </>
      )}
    </ButtonWrapper>
  );
};
