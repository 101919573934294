import { Copy06Icon } from "Icons/Copy06";
import { IconType } from "Icons/types";

import { Props as ButtonProps } from "components/shared/library/Button";

import { useAlert } from "contexts/alertContext";

import { Button } from "./Button";

type Props = {
  successMessage?: string;
  textToCopy: string;
  displayLabel?: boolean;
  iconPosition?: "left" | "right";
  icon?: IconType;
};

export const CopyToClipBoardIconButton = ({
  textToCopy,
  successMessage = "Text copied",
  ariaLabel = "Copy text",
  displayLabel,
  color = "purple",
  size = "medium",
  iconPosition = "left",
  background = "ghost",
  isFullWidth,
  icon = Copy06Icon,
}: Props &
  Pick<
    ButtonProps,
    "color" | "size" | "isFullWidth" | "background" | "ariaLabel"
  >) => {
  const { createAlert } = useAlert();
  return (
    <Button
      isFullWidth={isFullWidth}
      IconLeft={iconPosition === "left" ? icon : undefined}
      IconRight={iconPosition === "right" ? icon : undefined}
      color={color}
      background={background}
      size={size}
      ariaLabel={ariaLabel}
      onClick={() => {
        navigator.clipboard.writeText(textToCopy);
        createAlert("success", successMessage);
      }}
    >
      {displayLabel && ariaLabel}
    </Button>
  );
};
