import { CoinsHandIcon } from "Icons/CoinsHand";
import { LineChartUp02Icon } from "Icons/LineChartUp02";
import { PhoneCall01Icon } from "Icons/PhoneCall01";
import { Recording02Icon } from "Icons/Recording02";

import { css } from "@emotion/react";

import { useRouter } from "next/router";

import { Modal } from "components/shared/library/Modal";
import { Typography } from "components/shared/library/Typography";

import { getRecruitUrl } from "utils/urls";

import { purple, uiGray } from "constants/colors";
import { NUM_OF_FREE_RECRUIT_RESPONSES } from "constants/recruit";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const style = {
  wrapper: css({
    display: "flex",
    flexDirection: "column",
    gap: 24,
  }),
  contentWrapper: css({
    borderRadius: 16,
    border: `1px solid ${uiGray[10]}`,
    background: purple[50],
    padding: 16,
    display: "flex",
    gap: 16,
  }),
  content: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }),
};

export const RecruitAIModal = ({ isOpen, onClose }: Props) => {
  const router = useRouter();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Try Recruit AI"
      description="A simple, cost-effective way to screen candidates"
      confirmLabel="Connect Recruit AI"
      onConfirm={() => {
        router.push(getRecruitUrl());
      }}
    >
      <div css={style.wrapper}>
        <div css={style.contentWrapper}>
          <Recording02Icon stroke={purple[600]} />
          <div css={style.content}>
            <Typography weight="bold" size="M">
              Screen candidates with human-sounding AI
            </Typography>
            <Typography>
              Deliver a conversational experience that feels genuine and
              human-like with the efficiency of automation.
            </Typography>
          </div>
        </div>
        <div css={style.contentWrapper}>
          <PhoneCall01Icon stroke={purple[600]} />
          <div css={style.content}>
            <Typography weight="bold" size="M">
              Candidates can interview 24/7
            </Typography>
            <Typography>
              Allow candidates to complete interviews at their convenience,
              creating a seamless and stress-free experience.
            </Typography>
          </div>
        </div>
        <div css={style.contentWrapper}>
          <LineChartUp02Icon stroke={purple[600]} />
          <div css={style.content}>
            <Typography weight="bold" size="M">
              Get instant insights into candidates
            </Typography>
            <Typography>
              Swiftly filter through candidates using data-driven insights and
              rankings to identify top talent efficiently.
            </Typography>
          </div>
        </div>
        <div css={style.contentWrapper}>
          <CoinsHandIcon stroke={purple[600]} />
          <div css={style.content}>
            <Typography weight="bold" size="M">
              Only pay for interviews your candidates complete
            </Typography>
            <Typography>
              {`Start with ${NUM_OF_FREE_RECRUIT_RESPONSES} free completed interviews, then pay for as many interviews as you need.`}
            </Typography>
          </div>
        </div>
      </div>
    </Modal>
  );
};
