import { IconProps } from "Icons/types";

export const ArrowNarrowRightIcon = ({
  width = 24,
  height = 24,
  stroke,
  strokeGradient,
  strokeGradientId,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {strokeGradient && strokeGradientId && strokeGradient()}
    <path
      d="M4 12H20M20 12L14 6M20 12L14 18"
      stroke={
        strokeGradientId && strokeGradient
          ? `url(#${strokeGradientId})`
          : stroke
      }
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
