import { useRef } from "react";

import { css } from "@emotion/react";

import { useRouter } from "next/router";

import { usePageLayoutContext } from "contexts/PageLayoutContext";

import { useCloseOnClickOutside } from "utils/libraryHooks";
import { down, up } from "utils/mediaQueryStrings";

import { SLIDE_TRANSITION_DURATION } from "constants/animations";
import { uiGray, white } from "constants/colors";

import { MainSidePanel } from "./MainSidePanel";
import { RecruitSidePanel } from "./RecruitSidePanel";

const style = {
  blurriedBackdrop: css({
    width: "100%",
    height: "calc(100vh + 62px)",
    position: "absolute",
    background:
      "radial-gradient(93.12% 93.12% at 50% 50%, rgba(20, 13, 53, 0.25) 0%, rgba(0, 0, 0, 0) 100%)",
    backdropFilter: "blur(4px)",
    zIndex: 2,
  }),
  leftPanel: css({
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: white,
    borderRight: `1px solid ${uiGray[10]}`,
    overflow: "visible",
    transition: `width ${SLIDE_TRANSITION_DURATION}`,
    [down("mobile")]: {
      top: 62,
      zIndex: 2,
      position: "absolute",
      overflowY: "scroll",
      height: "100vh",
    },
  }),
  locked: css({ width: 280, height: "100vh" }),
  collapsed: css({
    width: 52,
    height: "100%",
    overflow: "visible",
    [down("mobile")]: {
      width: 0,
      top: 0,
    },
  }),
  SSR: css({
    width: 0,
    [up("mobile")]: {
      width: 280,
    },
  }),
  preview: css({
    width: 280,
    height: ["-webkit-fill-available", "-moz-available"],
    border: `1px solid ${uiGray[10]}`,
    boxShadow:
      "0px 12px 16px -4px rgba(27, 27, 27, 0.08), 0px 4px 6px -2px rgba(27, 27, 27, 0.03)",
    borderRadius: "0px 8px 8px 0px",
  }),
};

export const LeftPanel = () => {
  const {
    leftPanelStatus,
    setLeftPanelStatus,
    leftPanelDisplayButtonId,
    isLockable,
  } = usePageLayoutContext();
  const nodeRef = useRef(null);
  const router = useRouter();
  useCloseOnClickOutside(
    leftPanelStatus === "preview",
    (isPreviewVisible: boolean) =>
      setLeftPanelStatus(isPreviewVisible ? "preview" : "collapsed"),
    "left-panel",
    { exceptSelectors: [`#${leftPanelDisplayButtonId}`] }
  );

  return (
    <div
      css={[
        !isLockable && leftPanelStatus === "preview"
          ? style.blurriedBackdrop
          : undefined,
      ]}
    >
      <div
        ref={nodeRef}
        css={[style.leftPanel, style[leftPanelStatus]]}
        id="left-panel"
      >
        {router.pathname.includes("/recruit") ? (
          <RecruitSidePanel />
        ) : (
          <MainSidePanel />
        )}
      </div>
    </div>
  );
};
