import { ChevronLeftDoubleFilledIcon } from "Icons/ChevronLeftDoubleFilled";

import { useEffect, useState } from "react";

import { css } from "@emotion/react";

import { useRouter } from "next/router";

import { useAccount } from "contexts/AccountContext";

import { getRecruitUrl } from "utils/urls";

import { Authenticated } from "../library/Authenticated";
import { Button } from "../library/Button";
import RibbonLogo from "../library/RibbonLogo";
import { PlanBadge } from "../ribbonPro/PlanBadge";
import { RibbonProSuccessModal } from "../ribbonPro/RibbonProSuccessModal";

type Props = {
  showLogoOnly?: boolean;
  onClick?: () => void;
};

const style = {
  sidePanel: css({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  }),
  badge: css({
    whiteSpace: "nowrap",
  }),
  header: css({
    display: "flex",
    gap: 10,
    alignItems: "center",
  }),
};

export const BrandHeader = ({ showLogoOnly, onClick }: Props) => {
  const [isRibbonProSuccessModalOpen, setIsRibbonProSuccessModal] =
    useState(false); // we have success modal here because brand header will be visible from all parts of the app. We need to show success modal regardless of path.
  const router = useRouter();
  const { checkout, planId } = router.query;
  const { accountProfile } = useAccount(false);

  const isRecruit = router.asPath.includes(getRecruitUrl());

  useEffect(() => {
    if (planId === "pro" && checkout === "success") {
      setIsRibbonProSuccessModal(true);
    }
  }, [checkout, planId, router]);
  return (
    <div css={style.sidePanel}>
      <div css={style.header}>
        <RibbonLogo isLink showLogoOnly={showLogoOnly} />
        {!showLogoOnly && accountProfile?.plan && (
          <Authenticated>
            <div css={style.badge}>
              <PlanBadge plan={isRecruit ? "recruit" : accountProfile.plan} />
            </div>
          </Authenticated>
        )}
      </div>
      {!showLogoOnly && (
        <Button
          color="gray"
          isSquare
          background="ghost"
          size="small"
          onClick={onClick}
          IconLeft={ChevronLeftDoubleFilledIcon}
          ariaLabel="Collapse"
        />
      )}
      <RibbonProSuccessModal
        isOpen={isRibbonProSuccessModalOpen}
        onClose={() => setIsRibbonProSuccessModal(false)}
      />
    </div>
  );
};
