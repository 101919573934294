import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PostgrestError } from "@supabase/supabase-js";
import { logger } from "interfaces/logger";
import {
  addChatInfo,
  addChatMessageReceivedCount,
  rateMessage,
  selectChat,
  selectChatResponseReceivedCount,
} from "store/chatSlice";
import store from "store/store";

import { useAccount } from "contexts/AccountContext";

import { useRealtimeChannelHealth } from "utils/realtime";

import { supabase } from "./supabaseClient";

export type Chat = {
  id: string;
  userId: string;
  title: string;
  createdAt: string;
  deletedAt: string | null;
};

export type ChatDb = {
  id: string;
  user_id: string;
  title: string;
  created_at: string;
  deleted_at: string | null;
};

export type ChatRole = "user" | "system" | "assistant";

export type ChatMessage = {
  id: string;
  chatId: string;
  userId: string;
  rating?: 1 | -1;
  role: ChatRole;
  content: string;
  ratedAt?: string;
  createdAt: string;
};

type ContextType = "location" | "experiences" | "resume";

export type UserChatContext = {
  contextType: ContextType;
  documentFilename?: string;
  userId: string;
  description: string;
  text: string;
  updatedAt: string;
};

export const getChatInfo = async (
  id: string
): Promise<{ chat: Chat; chatMessages: ChatMessage[] }> => {
  const [chatResponse, messageResponse] = await Promise.all([
    supabase.from("chat").select("*").eq("id", id).single(),
    supabase
      .from("chat_message")
      .select("*")
      .eq("chat_id", id)
      .order("created_at", { ascending: true }),
  ]);

  if (chatResponse.error) {
    logger(chatResponse.error.message, "error");
    throw new Error(chatResponse.error.message);
  }
  if (messageResponse.error) {
    logger(messageResponse.error.message, "error");
    throw new Error(messageResponse.error.message);
  }

  const chat = formatDbChat(chatResponse.data as ChatDb);
  const chatMessages = messageResponse.data.map(
    (dbMessage) =>
      ({
        id: dbMessage.id,
        chatId: dbMessage.chat_id,
        userId: dbMessage.user_id,
        rating: dbMessage.rating || undefined,
        role: dbMessage.role,
        content: dbMessage.content,
        ratedAt: dbMessage.rated_at || undefined,
        createdAt: dbMessage.created_at,
      } as ChatMessage)
  );

  return { chat, chatMessages };
};

export const useChatInfo = (
  id?: string
): { chat: Chat; chatMessages: ChatMessage[] } | undefined => {
  const chatInfo = useSelector(selectChat(id));

  useEffect(() => {
    if (!chatInfo && id) {
      const get = async () =>
        store.dispatch(addChatInfo(await getChatInfo(id)));
      get();
    }
  }, [chatInfo, id]);
  if (chatInfo)
    return { chat: chatInfo.chat, chatMessages: chatInfo.chatMessages };
};

type RateAssistantMessageProps = {
  chatId: string;
  messageId: string;
  rating: 1 | -1;
};
export const rateAssistantMessage = async ({
  chatId,
  messageId,
  rating,
}: RateAssistantMessageProps) => {
  // get the message state before update
  const initialState = store
    .getState()
    .chat.chats[chatId].chatMessages.find(({ id }) => id === messageId);
  // update the message state in the store
  store.dispatch(rateMessage({ chatId, messageId, rating }));
  // update message in the db
  const { error } = await supabase
    .from("chat_message")
    .update({ rating, rated_at: "now()" })
    .eq("id", messageId);
  // if update failed, revert the store
  if (error && initialState) {
    store.dispatch(
      rateMessage({ chatId, messageId, rating: initialState.rating })
    );
  }
  // return the error
  return { error: error?.message };
};

export const formatDbChat = (data: ChatDb): Chat => ({
  id: data.id,
  userId: data.user_id,
  createdAt: data.created_at,
  title: data.title,
  deletedAt: data.deleted_at,
});

const findChatHistory = async (
  pageNumber: number,
  pageSize: number,
  userId: string
): Promise<Chat[]> => {
  const { data, error } = await supabase
    .from("chat")
    .select("*")
    .eq("user_id", userId)
    .filter("deleted_at", "is", "null")
    .range(
      (pageNumber - 1) * pageSize,
      (pageNumber - 1) * pageSize + pageSize - 1
    )
    .order("created_at", { ascending: false });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return (data as ChatDb[]).map(formatDbChat);
};

export const deleteChat = async (
  chatId: string
): Promise<{ error: PostgrestError | null }> => {
  const { error } = await supabase
    .from("chat")
    .update({ deleted_at: "now()" })
    .eq("id", chatId);
  return { error };
};

export const useChatHistory = () => {
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [isFinalPage, setIsFinalPage] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [chats, setChats] = useState<Chat[]>([]);
  const page_size = 5;
  const { accountProfile } = useAccount(true);
  const loadMoreChats = useCallback(async () => {
    setLoadingMore(false);
    const data = await findChatHistory(
      pageNumber + 1,
      page_size,
      accountProfile.id
    );
    setPageNumber(pageNumber + 1);
    setChats((prevState) => [...prevState, ...data]);
    setLoadingMore(true);
    if (data.length < page_size) {
      setIsFinalPage(true);
      return;
    }
    setIsFinalPage(false);
  }, [pageNumber, accountProfile.id]);

  useEffect(() => {
    if (pageNumber > 0) return;
    const loadMore = async () => {
      await loadMoreChats();
      setIsLoaded(true);
    };
    loadMore();
  }, [loadMoreChats, pageNumber]);

  const deleteChatFromHistory = async (
    chatId: string
  ): Promise<{ error: boolean }> => {
    const deletedChat = chats.find((chat) => chat.id === chatId);
    setChats((prev) => prev.filter((chat) => chat.id != chatId));
    const { error } = await deleteChat(chatId);
    if (error && deletedChat) setChats((prev) => [...prev, deletedChat]);
    return { error: !!error };
  };

  return {
    chats,
    isFinalPage,
    loadMoreChats,
    loadingMore,
    isLoaded,
    deleteChatFromHistory,
  };
};

export const getAssistantChatMessagesCount = async (
  userId: string
): Promise<number> => {
  const { data, error } = await supabase
    .from("chat_message")
    .select("id")
    .eq("user_id", userId)
    .eq("role", "assistant");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.length;
};

export const useChatMessageResponseCountRealtime = (
  userId: string
):
  | {
      isLoaded: true;
      used: number;
    }
  | {
      isLoaded: false;
      used: undefined;
    } => {
  const used = useSelector(selectChatResponseReceivedCount);
  const chatMessagesreceivedCountChannelName = "db-chat-messages-sent-count";
  const isChannelHealthy = useRealtimeChannelHealth(
    chatMessagesreceivedCountChannelName,
    !!userId
  );

  useEffect(() => {
    // only sub if user is logged in
    if (!userId || isChannelHealthy) return;

    const channel = supabase.channel(chatMessagesreceivedCountChannelName);

    channel.on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "chat_message",
        filter: `user_id=eq.${userId}`,
      },
      async () => {
        const used = await getAssistantChatMessagesCount(userId);
        store.dispatch(addChatMessageReceivedCount(used));
      }
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        const used = await getAssistantChatMessagesCount(userId);
        store.dispatch(addChatMessageReceivedCount(used));
      }
    });
  }, [userId, isChannelHealthy]);

  // we check that the value is a number, since 0 is falsy in javascript
  if (typeof used === "number") {
    return { used, isLoaded: true };
  } else {
    return { used: undefined, isLoaded: false };
  }
};
