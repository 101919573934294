import { Edit05Icon } from "Icons/Edit05";
import { Grid01Icon } from "Icons/Grid01";
import { Lock01Icon } from "Icons/Lock01";
import { PuzzlePieceIcon } from "Icons/PuzzlePiece";
import { SettingsIcon } from "Icons/Settings";
import { SwitchHorizontal02Icon } from "Icons/SwitchHorizontal02";

import { css } from "@emotion/react";

import { usePageLayoutContext } from "contexts/PageLayoutContext";

import { useRecruitPlanRealtime } from "apis/recruitingTeam";

import { down } from "utils/mediaQueryStrings";
import {
  getHomeUrl,
  getRecruitIntegrationsUrl,
  getRecruitInterviewsUrl,
  getRecruitSettingsUrl,
  getRecruitUrl,
} from "utils/urls";

import { purple } from "constants/colors";

import { Authenticated } from "../library/Authenticated";
import { ButtonLink } from "../library/ButtonLink";
import { InterviewCallsCounter } from "./InterviewCallsCounter";
import { SidePanel } from "./SidePanel";
import { SidePanelItem } from "./SidePanelItem";
import { UserAccountDisplay } from "./UserAccountDisplay";

const getStyles = (collapsed: boolean) => {
  return {
    content: css({
      display: "flex",
      flexDirection: "column",
      width: collapsed ? 36 : 240,
      gap: 16,
    }),
    userAccount: css({
      padding: !collapsed ? 0 : "0px 0px 16px 0px",
      [down("mobile")]: {
        marginTop: 24,
        padding: 0,
      },
    }),
    sidePanelItems: css({
      display: "flex",
      flexDirection: "column",
      gap: 8,
      marginTop: collapsed ? 12 : 0,
    }),
    bottomDiv: css({
      marginTop: "auto",
      display: "flex",
      flexDirection: "column",
      gap: 8,
      width: collapsed ? 36 : 240,
    }),
  };
};

export const RecruitSidePanel = () => {
  const { leftPanelStatus } = usePageLayoutContext();
  const { plan } = useRecruitPlanRealtime();
  const style = getStyles(leftPanelStatus === "collapsed");

  return (
    <SidePanel>
      <div css={style.content}>
        <Authenticated>
          <div css={style.userAccount}>
            <UserAccountDisplay
              disableClick
              showAvatarOnly={leftPanelStatus === "collapsed"}
            />
          </div>
        </Authenticated>
        <div css={style.sidePanelItems}>
          <Authenticated>
            <SidePanelItem
              label="Dashboard"
              link={getRecruitUrl()}
              Icon={Grid01Icon}
              disableChildPathAsActive
            />
          </Authenticated>
          <Authenticated>
            <SidePanelItem
              label="Interviews"
              link={getRecruitInterviewsUrl()}
              Icon={Edit05Icon}
            />
          </Authenticated>
          <Authenticated>
            <SidePanelItem
              label="Integrations"
              link={getRecruitIntegrationsUrl()}
              Icon={PuzzlePieceIcon}
              RightComponent={
                !plan?.hasIntegrationsAccess ? (
                  <Lock01Icon stroke={purple[600]} />
                ) : undefined
              }
            />
          </Authenticated>
          <Authenticated>
            <SidePanelItem
              label="Settings"
              link={getRecruitSettingsUrl()}
              Icon={SettingsIcon}
            />
          </Authenticated>
        </div>
      </div>
      <div css={style.bottomDiv}>
        {!(leftPanelStatus === "collapsed") && (
          <>
            <Authenticated>
              <InterviewCallsCounter />
            </Authenticated>
            <ButtonLink
              href={getHomeUrl()}
              background="ghost"
              color="gray"
              sameTab
              IconRight={SwitchHorizontal02Icon}
            >
              Switch to Ribbon
            </ButtonLink>
          </>
        )}
      </div>
    </SidePanel>
  );
};
