import { TrashIcon } from "Icons/Trash";

import { useState } from "react";

import { css } from "@emotion/react";

import Link from "next/link";
import { useRouter } from "next/router";

import { Button } from "components/shared/library/Button";
import { Typography } from "components/shared/library/Typography";

import { Chat } from "apis/chat";

import { getChatUrl } from "utils/urls";

import { uiGray } from "constants/colors";

import { DeleteModal } from "./DeleteModal";

const style = {
  wrapper: css({
    border: `1px solid ${uiGray[10]}`,
    padding: 16,
    height: 20,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    "&:hover .onHover": {
      visibility: "visible",
    },
    "&:hover": {
      background: uiGray[10],
      boxShadow: `0px 0px 0px 4px #E1E1E1`,
    },
  }),
  active: css({
    background: uiGray[20],
    border: `1px solid ${uiGray[50]}`,
  }),
  onHover: css({
    visibility: "hidden",
  }),
  ellipsis: css({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
  }),
  link: css({
    textDecoration: "none",
  }),
};

type Props = {
  chat: Chat;
  onDelete: (chatId: string) => Promise<{ error: boolean }>;
};

export const ChatButton = ({ chat, onDelete }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const router = useRouter();
  const { chatId } = router.query;
  return (
    <>
      <Link href={getChatUrl(chat.id)} css={style.link}>
        <div
          css={[style.wrapper, chatId == chat.id ? style.active : undefined]}
        >
          <Typography weight="medium" customCss={style.ellipsis}>
            {chat.title}
          </Typography>
          <div
            className="onHover"
            css={style.onHover}
            data-testid="hidden-trash-icon"
          >
            <Button
              onClick={(event) => {
                event.preventDefault();
                setIsModalOpen(true);
              }}
              IconRight={TrashIcon}
              ariaLabel="delete"
              background="ghost"
              color="black"
              size="medium"
            />
          </div>
        </div>
      </Link>
      <DeleteModal
        isModalOpen={isModalOpen}
        onDelete={() => onDelete(chat.id)}
        onClose={() => setIsModalOpen(false)}
        chatId={chat.id}
      />
    </>
  );
};
