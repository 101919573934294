import { BookmarkCheckIcon } from "Icons/BookmarkCheck";
import { InfinityIcon } from "Icons/Infinity";
import { RibbonLogoIcon } from "Icons/RibbonLogo";
import { useWindowSize } from "usehooks-ts";

import Confetti from "react-confetti";

import { css } from "@emotion/react";

import { down } from "utils/mediaQueryStrings";

import { confettiColors, purple, uiGray } from "constants/colors";
import { confettiDuration } from "constants/time";

import { Modal } from "../library/Modal";
import { Typography } from "../library/Typography";
import { PlanBadge } from "./PlanBadge";

const style = {
  title: css({
    display: "flex",
    gap: 8,
    alignItems: "center",
    whiteSpace: "nowrap",
  }),
  banner: css({
    borderRadius: 16,
    padding: "40px 24px 40px 24px",
    background: purple[300],
    [down("mobile")]: {
      display: "none",
    },
    width: 512,
  }),
  wrapper: css({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: 24,
  }),
  infoContent: css({
    display: "flex",
    gap: 8,
    alignItems: "center",
  }),
  confetti: css({
    position: "fixed",
    top: 0,
    left: 0,
  }),
  content: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  iconWrapper: css({
    borderRadius: 8,
    background: purple[50],
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
  }),
  bottomContent: css({
    padding: "0px 24px 0px 24px",
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const RibbonProSuccessModal = ({ isOpen, onClose }: Props) => {
  const { width, height } = useWindowSize();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <div css={style.title}>
          <Typography size={"XXL"} weight="bold">
            Welcome to Ribbon
          </Typography>
          <PlanBadge plan="pro" size={"large"} />
        </div>
      }
      description={
        <Typography color={uiGray[80]}>
          You have unlimited access to Copilot, Resume+, and Interview+.
        </Typography>
      }
      showCloseButton={false}
      confirmLabel="Start exploring"
      isCancelable={false}
      onConfirm={onClose}
    >
      <div css={style.wrapper}>
        <div css={style.banner}>
          <div css={style.content}>
            <Typography color="white" size="XL" weight="bold">
              Land your
            </Typography>
            <RibbonLogoIcon width={170} height={170} />
            <Typography color="white" size="XL" weight="bold">
              dream job
            </Typography>
          </div>
        </div>
        <div css={style.bottomContent}>
          <div css={style.infoContent}>
            <div css={style.iconWrapper}>
              <BookmarkCheckIcon width={24} height={24} stroke={purple[800]} />
            </div>
            <Typography weight="bold" size="M">
              Ability to save and apply to unlimited jobs
            </Typography>
          </div>
          <div css={style.infoContent}>
            <div css={style.iconWrapper}>
              <InfinityIcon width={24} height={24} stroke={purple[800]} />
            </div>
            <Typography weight="bold" size="M">
              Access to unlimited resume reviews
            </Typography>
          </div>
          <div css={style.infoContent}>
            <div css={style.iconWrapper}>
              <BookmarkCheckIcon width={24} height={24} stroke={purple[800]} />
            </div>
            <Typography weight="bold" size="M">
              Practice answering personalized interview questions
            </Typography>
          </div>
        </div>
      </div>
      {isOpen && (
        <div css={style.confetti}>
          <Confetti
            width={width}
            height={height}
            numberOfPieces={1000}
            tweenDuration={confettiDuration}
            colors={confettiColors}
            recycle={false}
          />
        </div>
      )}
    </Modal>
  );
};
